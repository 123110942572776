import * as React from 'react';
import { useIntl } from 'react-intl';
import {
  CmdRadioButton,
  CmdSimpleSelect,
  CmdChipSelect,
  CmdLabel,
  CmdSimpleSelectOption,
} from '@commander-services/gui-components';
import CmdBox from '@commander-services/cmd-box';
import { Controller, useForm } from 'react-hook-form';
import { useQueries } from '@tanstack/react-query';
import FormikValidatorService from '../../Services/FormikValidatorService';
import Section, { IFormSectionButton } from '../Forms/Section';
import { reportSettingsOptionsR09 } from './ReportSettings';
import { IOption } from '../Forms/CmdField';
import usePageWithForm from '../../hooks/usePageWithForm';
import * as ReportService from './ReportService';
import { IReportItem } from '.';
import HookFormService from '../../Services/HookFormService';

interface IReportR09InputForm {
  reportId: string;
  vehicleIds: string[];
  reportSettings: string[];
  format: string;
  year: number;
  consumptionType: string;
  unitType: string;
}

interface IReportForm {
  handleReportId: (id: string) => void;
  handleMainButton: (indexId: string, value: boolean) => void;
  selectedReportId: string;
  reportsList: IReportItem[];
  buttonDisabled: boolean;
  handleDownload: (url: string, values: any) => void;
}

export default function ReportR09(props: IReportForm) {
  usePageWithForm();
  const { formatMessage: f } = useIntl();
  const [validation, setValidation] = React.useState<any>({});
  const parseDateToday0 = new Date(new Date().setHours(0, 0, 0, 0));
  const [vehicleOptions, setVehicleOptions] = React.useState<IOption[]>([]);
  const [reportRange, setReportRange] = React.useState('');
  const [reportActionUrl, setReportActionUrl] = React.useState('');

  const currentYear = new Date().getFullYear();
  const yearOptions: CmdSimpleSelectOption[] = Array.from(
    { length: 5 },
    (_value: number, index: number) =>
      ({
        item: String(currentYear - index),
        value: String(currentYear - index),
      }) satisfies CmdSimpleSelectOption
  );

  const validateFields = (values: any) => {
    if (values) {
      props.handleMainButton('isDisabled', false);
      props.handleMainButton('hasError', false);
    }
    let formErrors: any = {};
    if (!validation) {
      return formErrors;
    }
    formErrors = FormikValidatorService.validateByValidationApiResponse(values, validation);
    const message = ReportService.handleDatepickerValidation(
      values?.date,
      props.selectedReportId,
      reportRange
    );
    if (message.length > 0) {
      formErrors.date = f({
        id: message,
      });
    }
    return HookFormService.transformErrors(formErrors);
  };

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<IReportR09InputForm>({
    mode: 'onChange',
    defaultValues: {
      reportId: props.selectedReportId,
      format: 'xlsx',
      year: parseDateToday0.getFullYear(),
      reportSettings: [],
      vehicleIds: [],
      consumptionType: 'can',
      unitType: 'kg',
    },
    resolver: async (data) => {
      const formErrors = validateFields(data);
      return {
        values: formErrors && Object.keys(formErrors).length ? {} : data, // if there's an error, return empty values
        errors: formErrors,
      };
    },
  });

  const onSubmit = async (values: any) => {
    if (reportActionUrl) {
      props.handleMainButton('isDisabled', true);
      const newValues = { ...values };
      Object.values(reportSettingsOptionsR09).forEach((item: string) => {
        newValues[item] = !!values.reportSettings.includes(item);
      });
      delete newValues.reportSettings;
      newValues.unit = newValues.unitType;
      newValues.consumption = newValues.consumptionType;
      delete newValues.date;
      delete newValues.unitType;
      delete newValues.consumptionType;
      props.handleDownload(reportActionUrl, newValues);
    }
  };

  const getValidation = async () => {
    const responseData = await ReportService.getValidation(props.selectedReportId);
    if (responseData) {
      setReportActionUrl(responseData?.actionUrl || '');
      setReportRange(responseData?.range || '');
      setValidation(responseData.form);
      if (responseData.userSettings) {
        setValue('consumptionType', responseData.userSettings?.consumption || 'can');
        setValue('unitType', responseData.userSettings?.unit || 'kg');
        if (responseData.userSettings.year) {
          setValue('year', responseData.userSettings.year);
        }
      }
    }
  };

  const getVehicles = async () => {
    const options: IOption[] | false = await ReportService.getVehicles(props.selectedReportId);
    if (options) {
      setVehicleOptions(options);
    }
  };

  useQueries({
    queries: [
      {
        queryKey: ['reportR09Validation'],
        queryFn: () => getValidation(),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
      {
        queryKey: ['reportR09Vehicles'],
        queryFn: () => getVehicles(),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    ],
  });

  const downloadButton: IFormSectionButton = {
    title: f({ id: 'reports.download' }),
    tooltip: f({ id: 'reports.download' }),
    disabled: props.buttonDisabled,
    buttonCallback: handleSubmit(onSubmit),
  };

  return (
    <div className="w-page new-css-wrapper" data-cy="reports-wrapper">
      <form
        className="form-horizontal"
        onSubmit={handleSubmit(onSubmit)}
        style={{ paddingTop: '30px' }}
        data-cy="reports-form"
      >
        <div className="container-fluid" data-cy="reports-container">
          <div data-cy="reports-sections">
            <div className="row" data-cy="reports-row">
              <div className="col-xxl-6" data-cy="reports-col">
                <Section
                  id="alarms-basic-information"
                  title={f({ id: 'alarms.section.basicInformation' })}
                  buttons={[downloadButton]}
                >
                  <Controller
                    name="reportId"
                    control={control}
                    rules={{ required: errors.reportId ? errors.reportId.message : undefined }}
                    render={({ field }) => (
                      <CmdSimpleSelect
                        {...field}
                        name="reportId"
                        id="reportId"
                        value={props.selectedReportId}
                        label={f({ id: 'reports.reportId' })}
                        introOption={f({ id: 'suppliersForm.choose' })}
                        options={props.reportsList}
                        required={true}
                        onChangeCallback={(value: string) => {
                          setValue('reportId', value);
                          props.handleReportId(value);
                        }}
                        error={errors.reportId?.message}
                        touched={true}
                      />
                    )}
                  />
                  <Controller
                    name="year"
                    control={control}
                    rules={{ required: errors.year ? errors.year.message : undefined }}
                    render={({ field }) => (
                      <CmdSimpleSelect
                        {...field}
                        name="year"
                        id="year"
                        label={f({ id: 'datepicker.year' })}
                        introOption={f({ id: 'suppliersForm.choose' })}
                        options={yearOptions}
                        required={true}
                        onChangeCallback={(value: string) => {
                          setValue('year', Number(value));
                          trigger('year');
                        }}
                      />
                    )}
                  />
                  <Controller
                    name="format"
                    control={control}
                    rules={{ required: errors.format ? errors.format.message : undefined }}
                    render={({ field }) => (
                      <CmdRadioButton
                        {...field}
                        id="format"
                        name="format"
                        label={f({ id: 'reports.format' })}
                        options={[{ item: '.xlsx', value: 'xlsx' }]}
                        required={true}
                        disabled={true}
                      />
                    )}
                  />
                  <Controller
                    name="vehicleIds"
                    control={control}
                    rules={{
                      required: errors.vehicleIds ? errors.vehicleIds.message : undefined,
                    }}
                    render={({ field }) => (
                      <CmdChipSelect
                        {...field}
                        id="vehicleIds"
                        name="vehicleIds"
                        label={f({ id: 'reports.vehicleIds' })}
                        // introOption={f({ id: 'form.choose' })}
                        options={vehicleOptions}
                        required={true}
                        placeholder={f({ id: 'form.choose' })}
                        searchPlaceholder={f({ id: 'form.search' })}
                        selectButton={f({ id: 'form.groupChipSelectUnique.button.apply' })}
                        resetButton={f({ id: 'form.groupChipSelectUnique.button.reset' })}
                        searchTooltip={f({
                          id: 'alarms.section.vehicles.driverId.search.tooltip',
                        })}
                        selectButtonTooltip={f({
                          id: 'alarms.section.vehicles.driverId.selectButton.tooltip',
                        })}
                        resetButtonTooltip={f({
                          id: 'alarms.section.vehicles.driverId.resetButton.tooltip',
                        })}
                        bossCheckbox={f({ id: 'alarms.modal.timeFrame.button.selectAll' })}
                        error={errors.vehicleIds?.message}
                        touched={true}
                        value={watch('vehicleIds')}
                        setValue={(value: string[]) => {
                          setValue('vehicleIds', value);
                          trigger('vehicleIds');
                        }}
                      />
                    )}
                  />
                </Section>
              </div>

              <div className="col-xxl-6" data-cy="reports-col">
                <CmdBox id="reports-sending-settings" title={f({ id: 'reports.reportSettings' })}>
                  {reportSettingsOptionsR09.length > 0 ? (
                    [
                      <Controller
                        name="consumptionType"
                        control={control}
                        rules={{
                          required: errors.consumptionType
                            ? errors.consumptionType.message
                            : undefined,
                        }}
                        render={({ field }) => (
                          <CmdRadioButton
                            {...field}
                            id="consumptionType"
                            name="consumptionType"
                            label={f({ id: 'reports.consumptionSettings' })}
                            options={[
                              {
                                item: f({ id: 'reports.R09.can' }),
                                value: 'can',
                              },
                              { item: f({ id: 'reports.R09.theo' }), value: 'theo' },
                            ]}
                            required={true}
                            customrGrid="1fr 1fr 1fr"
                            setValue={(value: string) => {
                              setValue('consumptionType', value);
                            }}
                            supportMessage={f({
                              id: 'reports.parametersSetting.consumptionTypeSettings.supportMessage',
                            })}
                          />
                        )}
                      />,
                      <Controller
                        name="unitType"
                        control={control}
                        rules={{
                          required: errors.unitType ? errors.unitType.message : undefined,
                        }}
                        render={({ field }) => (
                          <CmdRadioButton
                            {...field}
                            id="unitType"
                            name="unitType"
                            label={f({ id: 'reports.unitSettings' })}
                            options={[
                              {
                                item: f({ id: 'reports.R09.kg' }),
                                value: 'kg',
                              },
                              { item: f({ id: 'reports.R09.t' }), value: 't' },
                            ]}
                            required={true}
                            customrGrid="1fr 1fr 1fr"
                            setValue={(value: string) => {
                              setValue('unitType', value);
                            }}
                            supportMessage={f({
                              id: 'reports.parametersSetting.unitSettings.supportMessage',
                            })}
                          />
                        )}
                      />,
                    ]
                  ) : (
                    <CmdLabel
                      title={f({ id: 'reports.parametersSetting' })}
                      value={f({ id: 'reports.noParametersAvailable' })}
                      isLabelInTwoRows={true}
                    />
                  )}
                </CmdBox>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
