import { CZ_LANGUAGE, EN_LANGUAGE, HU_LANGUAGE } from '../../Services/LanguageService';

export const getOnlineHelpTopic = (topic: string): string => {
  const locale = localStorage.getItem('language');
  switch (topic) {
    case 'online-panel':
      switch (locale) {
        case EN_LANGUAGE:
          return '';
        case CZ_LANGUAGE:
          return '';
        case HU_LANGUAGE:
          return '';
        default:
          return '_online_panel';
      }
    case 'suppliers':
      switch (locale) {
        case EN_LANGUAGE:
          return '';
        case CZ_LANGUAGE:
          return '';
        case HU_LANGUAGE:
          return '';
        default:
          return '_dodavatelia';
      }
    case 'car-groups':
      switch (locale) {
        case EN_LANGUAGE:
          return '';
        case CZ_LANGUAGE:
          return '';
        case HU_LANGUAGE:
          return '';
        default:
          return '_skupiny_vozidiel';
      }
    case 'refuelings':
      switch (locale) {
        case EN_LANGUAGE:
          return '';
        case CZ_LANGUAGE:
          return '';
        case HU_LANGUAGE:
          return '';
        default:
          return 'prehladtankovani';
      }
    case 'add-refueling':
      switch (locale) {
        case EN_LANGUAGE:
          return '';
        case CZ_LANGUAGE:
          return '';
        case HU_LANGUAGE:
          return '';
        default:
          return '_pridat_tankovanie';
      }
    case 'edit-refueling':
      switch (locale) {
        case EN_LANGUAGE:
          return '';
        case CZ_LANGUAGE:
          return '';
        case HU_LANGUAGE:
          return '';
        default:
          return '_detail_tankovania';
      }
    case 'corrections':
      switch (locale) {
        case EN_LANGUAGE:
          return '';
        case CZ_LANGUAGE:
          return '';
        case HU_LANGUAGE:
          return '';
        default:
          return '_prehlad_korekcii';
      }
    case 'reports':
      switch (locale) {
        case EN_LANGUAGE:
          return '';
        case CZ_LANGUAGE:
          return '';
        case HU_LANGUAGE:
          return '';
        default:
          return '_report';
      }
    case 'alarms':
      switch (locale) {
        case EN_LANGUAGE:
          return '';
        case CZ_LANGUAGE:
          return '';
        case HU_LANGUAGE:
          return '';
        default:
          return 'alarmy';
      }
    case 'add-alarm':
      switch (locale) {
        case EN_LANGUAGE:
          return '';
        case CZ_LANGUAGE:
          return '';
        case HU_LANGUAGE:
          return '';
        default:
          return '_pridat_alarm';
      }
    case 'edit-alarm':
      switch (locale) {
        case EN_LANGUAGE:
          return '';
        case CZ_LANGUAGE:
          return '';
        case HU_LANGUAGE:
          return '';
        default:
          return '_detail_alarmu';
      }
    case 'gdpr-profiles':
      switch (locale) {
        case EN_LANGUAGE:
          return '';
        case CZ_LANGUAGE:
          return '';
        case HU_LANGUAGE:
          return '';
        default:
          return '_gdpr_profily';
      }
    case 'add-gdpr-profile':
      switch (locale) {
        case EN_LANGUAGE:
          return '';
        case CZ_LANGUAGE:
          return '';
        case HU_LANGUAGE:
          return '';
        default:
          return '_priradit_gdpr_profil';
      }
    case 'login':
      switch (locale) {
        case EN_LANGUAGE:
          return '';
        case CZ_LANGUAGE:
          return '';
        case HU_LANGUAGE:
          return '';
        default:
          return '_prihlasenie_do_systemu';
      }
    case 'ride-book':
      switch (locale) {
        case EN_LANGUAGE:
          return '';
        case CZ_LANGUAGE:
          return '';
        case HU_LANGUAGE:
          return '';
        default:
          return '_prehlad_jazd';
      }
    case 'auto-reports':
      switch (locale) {
        case EN_LANGUAGE:
          return '';
        case CZ_LANGUAGE:
          return '';
        case HU_LANGUAGE:
          return '';
        default:
          return '_automaticke_reporty';
      }
    case 'add-auto-report':
      switch (locale) {
        case EN_LANGUAGE:
          return '';
        case CZ_LANGUAGE:
          return '';
        case HU_LANGUAGE:
          return '';
        default:
          return '_novy_automaticky_report';
      }
    case 'period-lock':
      switch (locale) {
        case EN_LANGUAGE:
          return '';
        case CZ_LANGUAGE:
          return '';
        case HU_LANGUAGE:
          return '';
        default:
          return 'uzamknutieobdobia';
      }
    case 'map':
      switch (locale) {
        case EN_LANGUAGE:
          return '';
        case CZ_LANGUAGE:
          return '';
        case HU_LANGUAGE:
          return '';
        default:
          return '_mapa';
      }
    case 'map-control':
      switch (locale) {
        case EN_LANGUAGE:
          return '';
        case CZ_LANGUAGE:
          return '';
        case HU_LANGUAGE:
          return '';
        default:
          return '_ovladacie_prvky_na_mape';
      }
    case 'fuel-cards':
      switch (locale) {
        case EN_LANGUAGE:
          return '';
        case CZ_LANGUAGE:
          return '';
        case HU_LANGUAGE:
          return '';
        default:
          return '_palivove_karty';
      }
    case 'costs':
      switch (locale) {
        case EN_LANGUAGE:
          return '';
        case CZ_LANGUAGE:
          return '';
        case HU_LANGUAGE:
          return '';
        default:
          return '_prehlad_nakladov';
      }
    case 'expense-types':
      switch (locale) {
        case EN_LANGUAGE:
          return '';
        case CZ_LANGUAGE:
          return '';
        case HU_LANGUAGE:
          return '';
        default:
          return '_typy_nakladov';
      }
    case 'import-refuelings':
      switch (locale) {
        case EN_LANGUAGE:
          return '';
        case CZ_LANGUAGE:
          return '';
        case HU_LANGUAGE:
          return '';
        default:
          return 'importSuborov';
      }
    case 'vehicles':
      switch (locale) {
        case EN_LANGUAGE:
          return '';
        case CZ_LANGUAGE:
          return '';
        case HU_LANGUAGE:
          return '';
        default:
          return '_vozidla';
      }
    case 'employees':
      switch (locale) {
        case EN_LANGUAGE:
          return '';
        case CZ_LANGUAGE:
          return '';
        case HU_LANGUAGE:
          return '';
        default:
          return 'zamestnanci';
      }
    case 'add-employee':
      switch (locale) {
        case EN_LANGUAGE:
          return '';
        case CZ_LANGUAGE:
          return '';
        case HU_LANGUAGE:
          return '';
        default:
          return '_pridat_zamestnanca';
      }

    case 'edit-employee':
      switch (locale) {
        case EN_LANGUAGE:
          return '';
        case CZ_LANGUAGE:
          return '';
        case HU_LANGUAGE:
          return '';
        default:
          return '_detail_zamestnanca';
      }
    case 'codebooks':
      switch (locale) {
        case EN_LANGUAGE:
          return '';
        case CZ_LANGUAGE:
          return '';
        case HU_LANGUAGE:
          return '';
        default:
          return 'ciselniky';
      }
    case 'gdpr-profile':
      switch (locale) {
        case EN_LANGUAGE:
          return '';
        case CZ_LANGUAGE:
          return '';
        case HU_LANGUAGE:
          return '';
        default:
          return '_gdpr_profily';
      }
    case 'gdpr-users':
      switch (locale) {
        case EN_LANGUAGE:
          return '';
        case CZ_LANGUAGE:
          return '';
        case HU_LANGUAGE:
          return '';
        default:
          return '_pouzivatelia';
      }
    default:
      return '';
  }
};
