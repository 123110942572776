import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ICustomWidgetProps } from './Widget';
import { SIZE_1X1, SIZE_2X1, SIZE_4X1 } from './constants';
import {
  IOnlinePanelDataForWidgets,
  IRefuelingOverviewWidgetData,
} from '../../OnlineMapNew/PanelsLayer/interfaces';
import {
  formatDateString,
  formatTimestampRefuelingOverviewWidget,
  formatValuesRefuelingWidget,
} from './WidgetService';
import * as CurrencyService from '../../../Services/CurrencyService';
import RemoveButton from './RemoveButton';
import { isOnlinePanelOpenAtom } from '../OnlinePanelState';

const mockRefuelingOveriewData: IRefuelingOverviewWidgetData = {
  last: {
    amount: 43.56,
    price: 64.01,
    timestamp: '2023-12-20T09:45:00Z',
    currencyId: 5,
  },
  currentMonth: {
    prices: [{ amount: 0, price: 0, currencyId: 5 }],
    timestamp: [],
  },
  prevMonth: {
    prices: [
      { amount: 45.7, price: 128.02, currencyId: 5 },
      { amount: 345.7, price: 876.02, currencyId: 44 },
      { amount: 565.7, price: 576.02, currencyId: 18 },
    ],
    timestamp: ['2023-12-13T05:20:00Z', '2023-12-20T09:45:00Z'],
  },
};

const UNIT_ELECTRIC = 'kWh';

export default function ChargingOverviewWidget(props: ICustomWidgetProps): JSX.Element {
  const { formatMessage: f } = useIntl();
  const vehicleId = useRecoilValue<number | false>(isOnlinePanelOpenAtom);
  const onDragStart = props.isInGrid ? undefined : props.onDragCallback;
  const queryClient = useQueryClient();
  const chargingData: IOnlinePanelDataForWidgets | undefined = queryClient.getQueryData([
    'widgetsDataForOnlinePanel',
    vehicleId,
  ]);
  const [currentIndexCurrent, setCurrentIndexCurrent] = React.useState(0);
  const [currentIndexPrev, setCurrentIndexPrev] = React.useState(0);

  const prevMonth = () => {
    const lastMonthFirstDay = new Date(
      Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth() - 1, 1)
    );

    const lastMonthLastDay = new Date(
      Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), 0)
    );

    return [formatDateString(lastMonthFirstDay), formatDateString(lastMonthLastDay)];
  };

  const actualMonth = () => {
    const firstDayCurrentMonth = new Date(
      Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), 1)
    );

    const currentDay = new Date(
      Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())
    );

    return [formatDateString(firstDayCurrentMonth), formatDateString(currentDay)];
  };

  const handleOnClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    const dateKey = event.currentTarget.getAttribute('data-id');

    if (dateKey) {
      const urlString = `/refuelings/${vehicleId}`;

      const url = new URL(urlString, window.location.origin);

      // Check if dateKey is an array
      if (Array.isArray(dateKey) && dateKey.length > 0) {
        // Join the dates with a separator (e.g., comma) and add as a single query parameter
        const dateSegment = dateKey.join(',');
        url.searchParams.append('date', dateSegment);
      } else if (typeof dateKey === 'string' && dateKey) {
        // Handle a single date string and add as a query parameter
        url.searchParams.append('date', dateKey);
      } // If dateKey is empty or invalid, no 'date' parameter is added

      // Open the URL
      window.open(url.toString(), '_blank');
    }
  };

  const handleWidgetTooltip = () => {
    if (props.data.columnSize === 1 && props.data.rowSize === 1 && !props.isMockupMode) {
      return f({ id: 'refueling.overview.widget.last.tooltip' });
    }
    if (!props.isInGrid && props.isMockupMode) {
      return f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' });
    }
    return '';
  };

  const { data: currencyData } = useQuery({
    queryKey: ['currenciesRefuelingOverviewWidget'],
    queryFn: () => CurrencyService.getCurrencies(),
    enabled: !props.isMockupMode,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  let formattedData = formatValuesRefuelingWidget(
    mockRefuelingOveriewData,
    currencyData || [],
    UNIT_ELECTRIC
  );

  if (props.isMockupMode) {
    formatValuesRefuelingWidget(mockRefuelingOveriewData, currencyData || [], UNIT_ELECTRIC);
  } else if (!Array.isArray(chargingData?.charging_overview)) {
    formattedData = formatValuesRefuelingWidget(
      chargingData?.charging_overview || undefined,
      currencyData || [],
      UNIT_ELECTRIC
    );
  }

  React.useEffect(() => {
    const intervalCurrent = setInterval(() => {
      setCurrentIndexCurrent((prevIndex) => (prevIndex + 1) % formattedData.currentMonth.length);
    }, 3000); // Change currentMonth data every 3 seconds

    return () => clearInterval(intervalCurrent);
  }, [formattedData.currentMonth.length]);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndexPrev((prevIndex) => (prevIndex + 1) % formattedData.prevMonth.length);
    }, 3000);
    return () => clearInterval(intervalId);
  }, [formattedData.prevMonth.length]);

  const handleClickAbleWidget = (size: string) => {
    if (size === SIZE_1X1) {
      return 'is-clickable';
    }
    if (size === SIZE_2X1 && formattedData.last !== '—') {
      return 'is-clickable';
    }
    if (
      size === SIZE_4X1 &&
      (formattedData.currentMonth[0] !== '—' ||
        formattedData.prevMonth[0] !== '—' ||
        formattedData.last !== '—')
    ) {
      return 'is-clickable';
    }
    return '';
  };

  const handleDateId = (size: string) => {
    if (size === SIZE_1X1) {
      // const currentMonth = [
      //   formatDateString(new Date(new Date().setDate(1))),
      //   formatDateString(new Date()),
      // ];
      // if (chargingData && chargingData.charging_overview.currentMonth.timestamp.length > 0) {
      //   return chargingData?.charging_overview.currentMonth.timestamp;
      // }

      return actualMonth();
    }
    if (size === SIZE_2X1 && !Array.isArray(chargingData?.charging_overview)) {
      return chargingData?.charging_overview?.last?.timestamp;
    }

    return [];
  };

  return (
    <div
      data-cy="charging-overview-widget"
      className={`b-widget ${handleClickAbleWidget(props.data.size)} b-widget--${
        props.data.rowSize
      }x${props.data.columnSize}
      widget-grid-name-${props.data.gridName} ${
        props.data.hasIcon && props.data.columnSize > 2
          ? 'b-widget--alignLeft b-widget--carInfo'
          : ''
      } ${props.data.rowSize === 2 ? ' b-widget--2rows' : ''} widget-grid-name-${
        props.data.gridName
      } ${props.isInGrid ? '' : 'droppable-element'}`}
      draggable={!props.isInGrid}
      onDragStart={onDragStart}
      title={handleWidgetTooltip()}
      style={{
        position: 'relative',
        cursor: `${handleClickAbleWidget(props.data.size) ? 'pointer' : 'default'}`,
      }}
      onClick={
        (props.data.size === SIZE_1X1 || props.data.size === SIZE_2X1) && !props.isMockupMode
          ? handleOnClick
          : undefined
      }
      data-id={props.isMockupMode ? [] : handleDateId(props.data.size)}
    >
      {props.isInGrid && (
        <RemoveButton
          dataCy="charging-overview-widget-in-grid"
          onRemoveCallback={props.onRemoveCallback}
          gridName={props.data.gridName}
        />
      )}
      {props.data.size === SIZE_1X1 && (
        <i
          data-cy="charging-overview-widget-icon-size-1-row-1"
          className="e-widget-icon cmd-icon cmd-icon-charging-vehicle-yes"
        />
      )}
      {props.data.size === SIZE_2X1 && (
        <>
          <h4 className="e-widget-title e-widget-title--sm">
            {f({ id: 'enum.onlineMap.widget.types.chargingOverview' })}
          </h4>
          <div
            className="row no-gutters"
            title={`${f({ id: 'enum.onlineMap.widget.types.chargingOverview' })}: ${
              formattedData.last
            }  ${formatTimestampRefuelingOverviewWidget(
              props.isMockupMode || Array.isArray(chargingData?.charging_overview)
                ? mockRefuelingOveriewData.last.timestamp
                : chargingData?.charging_overview?.last.timestamp || ''
            )}`}
          >
            <div className="col-auto">
              <i
                data-cy="charging-overview-widget-icon-size-4-row-2"
                className="e-widget-icon cmd-icon cmd-icon-charging-vehicle-yes"
              />
            </div>
            <div className="col">
              <div className="info">
                <div className="text-truncate">
                  <strong>{formattedData.last} </strong>
                </div>
              </div>
              <div className="info">
                <div className="text-truncate">
                  {formatTimestampRefuelingOverviewWidget(
                    props.isMockupMode || Array.isArray(chargingData?.charging_overview)
                      ? mockRefuelingOveriewData.last.timestamp
                      : chargingData?.charging_overview?.last.timestamp || ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {props.data.size === SIZE_4X1 && (
        <>
          <i
            data-cy="charging-overview-widget-icon-size-4-row-1"
            className="e-widget-icon cmd-icon cmd-icon-charging-vehicle-yes"
          />
          <div
            className="info"
            onClick={props.isMockupMode || formattedData.last === '—' ? undefined : handleOnClick}
            data-id={
              Array.isArray(chargingData?.charging_overview)
                ? []
                : chargingData?.charging_overview?.last.timestamp
            }
            title={`${f({
              id: 'enum.onlineMap.widget.types.chargingOverview',
            })}: ${formattedData.last} ${formatTimestampRefuelingOverviewWidget(
              props.isMockupMode || Array.isArray(chargingData?.charging_overview)
                ? mockRefuelingOveriewData.last.timestamp
                : chargingData?.charging_overview?.last.timestamp || ''
            )}`}
          >
            <div className="text-truncate">
              <strong>{`${f({ id: 'enum.onlineMap.widget.types.chargingOverview' })}:`}</strong>{' '}
              {formattedData.last !== '—' ? (
                <a href="#">
                  <span>
                    {`${formattedData.last} ${formatTimestampRefuelingOverviewWidget(
                      props.isMockupMode || Array.isArray(chargingData?.charging_overview)
                        ? mockRefuelingOveriewData.last.timestamp
                        : chargingData?.charging_overview?.last.timestamp || ''
                    )}`}{' '}
                  </span>
                </a>
              ) : (
                <span>
                  {`${formattedData.last} ${formatTimestampRefuelingOverviewWidget(
                    props.isMockupMode || Array.isArray(chargingData?.charging_overview)
                      ? mockRefuelingOveriewData.last.timestamp
                      : chargingData?.charging_overview?.last.timestamp || ''
                  )}`}{' '}
                </span>
              )}
            </div>
          </div>
          <div
            className="info"
            onClick={
              props.isMockupMode || formattedData.currentMonth[0] === '—'
                ? undefined
                : handleOnClick
            }
            data-id={actualMonth()}
            title={`${f({
              id: 'refueling.overview.widget.currentMonth.title',
            })}:\n ${formattedData.currentMonth.map((item) => item).join(', \n')}.`}
          >
            <div className="text-truncate">
              <strong>{`${f({ id: 'refueling.overview.widget.currentMonth.title' })}:`}</strong>{' '}
              {formattedData.currentMonth[0] !== '—' ? (
                <a href="#">
                  <span>{formattedData.currentMonth[currentIndexCurrent]}</span>
                </a>
              ) : (
                <span>{formattedData.currentMonth[currentIndexCurrent]}</span>
              )}
            </div>
          </div>
          <div
            className="info"
            onClick={
              props.isMockupMode || formattedData.prevMonth[0] === '—' ? undefined : handleOnClick
            }
            data-id={prevMonth()}
            title={`${f({
              id: 'date.prevMonth.shortcut',
            })}:\n ${formattedData.prevMonth.map((item) => item).join(', \n')}.`}
          >
            <div className="text-truncate">
              <strong>{`${f({ id: 'date.prevMonth.shortcut' })}:`}</strong>{' '}
              {formattedData.prevMonth[0] !== '—' ? (
                <a href="#">
                  <span>{formattedData.prevMonth[currentIndexPrev]}</span>
                </a>
              ) : (
                <span>{formattedData.prevMonth[currentIndexPrev]}</span>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
