import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import dayjs from 'dayjs';
import localforage from 'localforage';
import { ICustomWidgetProps } from './Widget';
import { isOnlinePanelOpenAtom, IVehicleOnlinePanels, onlinePanelAtom } from '../OnlinePanelState';
import { URL_CORRECTIONS } from '../../../router/constants';
import RemoveButton from './RemoveButton';

interface IWidgetData {
  date: string;
  odometer: number;
  lastTachoCorrecxtion?: string;
  realValue?: number;
}

export default function VehicleCardWidget(props: ICustomWidgetProps): JSX.Element {
  const { formatMessage: f } = useIntl();
  const [widgetData, setWidgetData] = React.useState<IWidgetData | null>({
    date: '2023-04-20T11:36:57Z',
    odometer: 454646464,
  });
  const onlinePanelData = useRecoilValue<IVehicleOnlinePanels>(onlinePanelAtom);
  const vehicleId = useRecoilValue<number | false>(isOnlinePanelOpenAtom);

  React.useMemo(() => {
    if (!props.isMockupMode) {
      const onlinePanel = onlinePanelData[Number(vehicleId)];

      if (onlinePanel && onlinePanel.mileage) {
        setWidgetData({
          date: onlinePanel.mileage.timestamp,
          odometer: onlinePanel.mileage.value,
        });
      } else {
        setWidgetData(null);
      }
    }
  }, [onlinePanelData, props.isMockupMode, vehicleId]);

  const handleSettingsClick = () => {
    if (!props.isMockupMode && !props.isInGrid) {
      localforage.setItem('lastClickedVehicleId', String(vehicleId));
      window.open(`${URL_CORRECTIONS}/${vehicleId}`, '_blank');
    }
  };

  // const renderTooltip = () => {
  //   return (
  //     <div style={{ color: '#fff' }}>
  //       <div>
  //         {f({ id: 'table_list.columns.refuelings.mileageDiffKm' })}: {widgetData?.odometer} km
  //       </div>
  //       <div>
  //         {f({ id: 'onlinepanel.validFor' })}:{' '}
  //         {widgetData && widgetData.date ? dayjs(widgetData.date).format('DD.MM.YYYY HH:mm') : ''}
  //       </div>
  //       <div>
  //         {f({ id: 'onlinepanel.lastTachoCorrection' })}:{' '}
  //         {widgetData && widgetData.lastTachoCorrecxtion
  //           ? dayjs(widgetData.lastTachoCorrecxtion).format('DD.MM.YYYY HH:mm')
  //           : ''}
  //       </div>
  //       <div>
  //         - {f({ id: 'onlinepanel.real' })}:{' '}
  //         {widgetData && widgetData.realValue ? `${widgetData.realValue} km` : ''}
  //       </div>
  //     </div>
  //   );
  // };

  const getTooltip = () => {
    const odometerName = f({ id: 'tripsOverview.additionalInformation.item.odometer' });
    const validFor = f({ id: 'onlinepanel.validFor' });
    const odometerData = widgetData?.odometer.toFixed(2) || '';
    const date = widgetData?.date ? dayjs(widgetData.date).format('DD.MM.YYYY HH:mm') : '';

    return `${odometerName}: ${odometerData} km\n${validFor}: ${date}`;
  };

  return (
    <div
      data-cy="odometer-widget"
      className={`b-widget is-clickable b-widget--drivers-card b-widget--${props.data.rowSize}x${
        props.data.columnSize
      } ${props.data.columnSize > 1 ? 'b-widget--alignLeft' : ''} widget-grid-name-${
        props.data.gridName
      } ${props.isInGrid ? '' : 'droppable-element'}`}
      draggable={!props.isInGrid}
      onDragStart={props.isInGrid ? undefined : props.onDragCallback}
      title={
        !props.isInGrid && props.isMockupMode
          ? f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' })
          : getTooltip()
      }
    >
      {' '}
      {props.isInGrid && (
        <RemoveButton
          dataCy="odometer-widget-in-grid"
          onRemoveCallback={props.onRemoveCallback}
          gridName={props.data.gridName}
        />
      )}
      {props.data.columnSize === 4 && props.data.hasIcon && (
        <div
          onClick={handleSettingsClick}
          className="row no-gutters"
          data-cy="odometer-widget-size-4"
        >
          <div className="col-auto">
            <i
              style={{ cursor: !props.isMockupMode && !props.isInGrid ? 'pointer' : '' }}
              className="e-widget-icon e-widget-icon--md cmd-icon cmd-icon-mileage"
            />
          </div>
          <div className="col">
            <div className="info" data-cy="odometer-widget-date">
              <strong>{f({ id: 'onlinepanel.date' })}:&nbsp;</strong>
              <span>
                {widgetData && widgetData.date
                  ? dayjs(widgetData.date).format('DD.MM.YYYY HH:mm')
                  : ''}
              </span>
            </div>
            <div className="info" data-cy="odometer-widget-value">
              <div
                className="text-truncate"
                style={{ width: '100%' }}
                // title={widgetData ? `${widgetData.odometer} km` : undefined}
              >
                <strong>{f({ id: 'enum.onlineMap.widget.types.odometer' })}: </strong>
                <span>{widgetData ? `${widgetData?.odometer.toFixed(2)} km` : ''}</span>
              </div>
            </div>
          </div>
        </div>
      )}
      {props.data.hasIcon && props.data.columnSize === 1 && (
        <i
          data-cy="odometer-widget-icon"
          className="e-widget-icon cmd-icon cmd-icon-mileage"
          style={{
            marginRight: 0,
            cursor: !props.isMockupMode && !props.isInGrid ? 'pointer' : '',
          }}
          onClick={handleSettingsClick}
        />
      )}
    </div>
  );
}
