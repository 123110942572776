import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  isOnlinePanelOpenAtom,
  IVehicleOnlinePanels,
  IWidgetGraph,
  onlinePanelAtom,
} from '../OnlinePanelState';
import { ICustomWidgetProps } from './Widget';
import { IVehicleData } from '../../Vehicles/types';
import Config from '../../../../config';
import { fuelLevelPercWidgetGraphAtom } from '../../Graphs/GraphState';
import RemoveButton from './RemoveButton';
import { getWidgetTooltip, getWidgetValue, isWidgetDisabled } from './WidgetService';
import { vehicleDataByIdSelector } from '../../../store/recoil/vehiclesData';

export const FUEL_LEVEL_PERCENTAGE = 'consPerc';

export default function FuelLevelPercentageWidget(props: ICustomWidgetProps): JSX.Element {
  const isGraphEnabled = Config.widgets.FuelLevelPercentageWidget.enableGraph;
  const { formatMessage: f } = useIntl();
  const [widgetData, setWidgetData] = React.useState<number | null>(67);
  const valueType = '%';
  const vehicleId = useRecoilValue<number | false>(isOnlinePanelOpenAtom);
  const onlinePanelData = useRecoilValue<IVehicleOnlinePanels>(onlinePanelAtom);
  const vehicleData = useRecoilValue<IVehicleData | null>(vehicleDataByIdSelector(vehicleId));
  const setFuelLevelPercWidgetGraph = useSetRecoilState<IWidgetGraph | null>(
    fuelLevelPercWidgetGraphAtom
  );
  const shortName = f({ id: 'widget.fuelLevel.valueName.short' });
  const longName = f({ id: 'widget.fuelLevel.valueName.long' });
  const LOW_PERCENTAGE = 12;

  React.useMemo(() => {
    if (!props.isMockupMode) {
      const onlinePanel = onlinePanelData[Number(vehicleId)];

      if (onlinePanel && onlinePanel.fuelLevelPercentage !== null) {
        const roundedValue = Number(onlinePanel.fuelLevelPercentage).toFixed(2);
        setWidgetData(Number(roundedValue));
      } else {
        setWidgetData(null);
      }
    }
  }, [onlinePanelData, props.isMockupMode, vehicleId]);

  const handleDetail = () => {
    if (isGraphEnabled && typeof widgetData === 'number' && vehicleData) {
      // const rideId = vehicleData.lastRide?.id;
      setFuelLevelPercWidgetGraph({
        vehicleId: vehicleId || 0,
        name: FUEL_LEVEL_PERCENTAGE,
      });
    }
  };

  return (
    <div
      onClick={props.isMockupMode ? undefined : handleDetail}
      style={props.isMockupMode ? undefined : isGraphEnabled ? { cursor: 'pointer' } : {}}
      data-cy="fuel-percentage-widget"
      className={`b-widget is-clickable b-widget--${props.data.rowSize}x${props.data.columnSize} ${
        props.data.hasIcon && props.data.columnSize > 1 ? 'b-widget--alignLeft' : ''
      } widget-grid-name-${props.data.gridName} ${props.isInGrid ? '' : 'droppable-element'}`}
      draggable={!props.isInGrid}
      onDragStart={props.isInGrid ? undefined : props.onDragCallback}
      title={getWidgetTooltip(
        f({ id: 'widget.fuelLevel.tooltip' }, { valueType }),
        f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' }),
        props.isInGrid,
        props.isMockupMode
      )}
    >
      {props.isInGrid && (
        <RemoveButton
          dataCy="fuel-percentage-widget-in-grid"
          onRemoveCallback={props.onRemoveCallback}
          gridName={props.data.gridName}
        />
      )}
      {props.data.hasTitle && props.data.columnSize === 1 && !props.data.hasIcon && (
        <h4 data-cy="fuel-percentage-widget-title-size-1" className="e-widget-title">
          {shortName}
        </h4>
      )}
      {props.data.hasTitle && props.data.columnSize > 1 && !props.data.hasIcon && (
        <h4 data-cy="fuel-percentage-widget-title-size-more-1" className="e-widget-title">
          {longName}
        </h4>
      )}
      {props.data.hasTitle && props.data.columnSize === 2 && props.data.hasIcon && (
        <h4 data-cy="fuel-percentage-widget-title-size-2" className="e-widget-title">
          {longName}
        </h4>
      )}
      {props.data.hasIcon && props.data.columnSize > 1 && (
        <div className="row no-gutters" data-cy="fuel-percentage-widget-icon-size-more-1">
          <div className="col-auto">
            {props.data.columnSize === 2 && (
              <i
                className={`e-widget-icon cmd-icon cmd-icon-gas ${
                  widgetData !== null && !props.isMockupMode && widgetData < LOW_PERCENTAGE
                    ? 'active'
                    : ''
                } ${isWidgetDisabled(widgetData)}`}
              />
            )}
            {props.data.columnSize === 3 && (
              <i
                className={`e-widget-icon e-widget-icon--md cmd-icon cmd-icon-gas ${
                  widgetData !== null && !props.isMockupMode && widgetData < LOW_PERCENTAGE
                    ? 'active'
                    : ''
                } ${isWidgetDisabled(widgetData)}`}
              />
            )}
          </div>
          <div className="col">
            {props.data.hasTitle && props.data.columnSize === 3 && (
              <h4 className="e-widget-title">{longName}</h4>
            )}
            <div className={`e-widget-value e-widget-value--md ${isWidgetDisabled(widgetData)}`}>
              {getWidgetValue(widgetData, props.data.hasUnitType, valueType)}
            </div>
          </div>
        </div>
      )}
      {!props.data.hasIcon && props.data.columnSize > 1 && (
        <div
          data-cy="fuel-percentage-widget-value-size-more-1"
          className={`e-widget-value e-widget-value--md ${isWidgetDisabled(widgetData)}`}
        >
          {getWidgetValue(widgetData, props.data.hasUnitType, valueType)}
        </div>
      )}
      {props.data.hasIcon && props.data.columnSize === 1 && (
        <i
          data-cy="fuel-percentage-widget-icon"
          className={`e-widget-icon cmd-icon cmd-icon-gas ${
            widgetData !== null && !props.isMockupMode && widgetData < LOW_PERCENTAGE
              ? 'active'
              : ''
          } ${isWidgetDisabled(widgetData)}`}
        />
      )}
      {props.data.columnSize === 1 && (
        <div
          data-cy="fuel-percentage-widget-value-size-1"
          className={`e-widget-value ${isWidgetDisabled(widgetData)}`}
        >
          {getWidgetValue(widgetData, props.data.hasUnitType, valueType)}
        </div>
      )}
    </div>
  );
}
