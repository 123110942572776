import * as React from 'react';
import { useIntl } from 'react-intl';
import {
  CmdRadioButton,
  CmdSimpleSelect,
  CmdChipSelect,
  CmdCheckboxGroup,
  CmdLabel,
  CmdSwitch,
  CmdCheckbox,
} from '@commander-services/gui-components';
import CmdBox from '@commander-services/cmd-box';
import { Controller, useForm } from 'react-hook-form';
import { useQueries } from '@tanstack/react-query';
import FormikValidatorService from '../../Services/FormikValidatorService';
import Section, { IFormSectionButton } from '../Forms/Section';
import {
  reportSettingsOptionsR07,
  reportSettingsOptionsR07Differentiation,
} from './ReportSettings';
import { IOption } from '../Forms/CmdField';
import usePageWithForm from '../../hooks/usePageWithForm';
import Calendar from '../Forms/ReactHookFormFields/Calendar';
import HookFormService from '../../Services/HookFormService';
import { IReportItem } from './types';
import {
  getValidation,
  getVehicles,
  handleAllowedRange,
  handleDatepickerValidation,
  handleReportSettings,
} from './ReportService';

interface IReportR07InputForm {
  reportId: string;
  vehicleIds: string[];
  reportSettings: string[];
  format: string;
  date: Date[];
  mergeBy: string;
  consumptionType: string;
  showEmpty: number;
  differentiation: string[];
}

interface IReportForm {
  handleReportId: (id: string) => void;
  handleMainButton: (indexId: string, value: boolean) => void;
  selectedReportId: string;
  reportsList: IReportItem[];
  buttonDisabled: boolean;
  handleDownload: (url: string, values: any) => void;
}

export default function ReportR07(props: IReportForm) {
  usePageWithForm();
  const { formatMessage: f } = useIntl();
  const [validation, setValidation] = React.useState<any>({});
  const parseDateToday0 = new Date(new Date().setHours(0, 0, 0, 0));
  const parseDateToday24 = new Date(new Date().setHours(23, 59, 0, 0));
  const [vehicleOptions, setVehicleOptions] = React.useState<IOption[]>([]);
  const [reportRange, setReportRange] = React.useState('');
  const [reportActionUrl, setReportActionUrl] = React.useState('');
  const [bossCheckboxValue, setBossCheckboxValue] = React.useState('');

  const getReportSettingsOptions = () => {
    return reportSettingsOptionsR07.map((item: string) => {
      return { value: item, item: f({ id: `reports.R07.${item}` }) };
    });
  };

  const getReportSettingsOptionsDifferentiation = () => {
    return reportSettingsOptionsR07Differentiation.map((item: string) => {
      return { value: item, item: f({ id: `reports.R07.${item}` }) };
    });
  };

  const validateFields = (values: any) => {
    if (values) {
      props.handleMainButton('isDisabled', false);
      props.handleMainButton('hasError', false);
    }
    let formErrors: any = {};
    if (!validation) {
      return formErrors;
    }
    formErrors = FormikValidatorService.validateByValidationApiResponse(values, validation);
    const message = handleDatepickerValidation(values?.date, props.selectedReportId, reportRange);
    if (message.length > 0) {
      formErrors.date = f({
        id: message,
      });
    }
    return HookFormService.transformErrors(formErrors);
  };

  const {
    handleSubmit,
    watch,
    control,
    setError,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<IReportR07InputForm>({
    mode: 'onChange',
    defaultValues: {
      reportId: props.selectedReportId,
      mergeBy: '',
      format: 'xlsx',
      date: [parseDateToday0, parseDateToday24],
      reportSettings: [],
      vehicleIds: [],
      consumptionType: 'refueling',
      showEmpty: 0,
      differentiation: [],
    },
    resolver: async (data) => {
      const formErrors = validateFields(data);
      return {
        values: formErrors && Object.keys(formErrors).length ? {} : data, // if there's an error, return empty values
        errors: formErrors,
      };
    },
  });

  const onSubmit = async (values: any) => {
    if (reportActionUrl) {
      props.handleMainButton('isDisabled', true);
      const newValues = { ...values };
      Object.values(reportSettingsOptionsR07).forEach((item: string) => {
        newValues[item] = !!values.reportSettings.includes(item);
      });
      Object.values(reportSettingsOptionsR07Differentiation).forEach((item: string) => {
        newValues[item] = !!values.differentiation.includes(item);
      });
      delete newValues.reportSettings;
      delete newValues.differentiation;
      newValues.dateFrom = values.date[0];
      newValues.dateTo = values.date[1];
      delete newValues.date;
      props.handleDownload(reportActionUrl, newValues);
    }
  };

  const getReportValidation = async () => {
    const responseData = await getValidation(props.selectedReportId);
    if (responseData) {
      setReportActionUrl(responseData?.actionUrl || '');
      setReportRange(responseData?.range || '');
      setValidation(responseData.form);
      setValue(
        'reportSettings',
        handleReportSettings(responseData.userSettings, reportSettingsOptionsR07)
      );
      setValue(
        'differentiation',
        handleReportSettings(responseData.userSettings, reportSettingsOptionsR07Differentiation)
      );
    }
  };

  const getReportVehicles = async () => {
    const options: IOption[] | false = await getVehicles(props.selectedReportId);
    if (options) {
      setVehicleOptions(options);
    }
  };

  const handleReportParameter = (value: string) => {
    if (value === 'checked') {
      setValue('reportSettings', reportSettingsOptionsR07);
      setValue('differentiation', reportSettingsOptionsR07Differentiation);
      setBossCheckboxValue('checked');
      setError('differentiation', { message: '' });
      setError('reportSettings', { message: '' });
    } else {
      setValue('reportSettings', []);
      setValue('differentiation', []);
      setBossCheckboxValue('');
    }
  };

  React.useEffect(() => {
    if (watch('reportSettings').length === 0 && watch('differentiation').length === 0) {
      setBossCheckboxValue('');
    } else if (
      watch('reportSettings').length + watch('differentiation').length ===
      reportSettingsOptionsR07.length + reportSettingsOptionsR07Differentiation.length
    ) {
      setBossCheckboxValue('checked');
    } else {
      setBossCheckboxValue('indeterminate');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('reportSettings'), watch('differentiation')]);

  useQueries({
    queries: [
      {
        queryKey: ['reportR07Validation'],
        queryFn: () => getReportValidation(),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
      {
        queryKey: ['reportR07Vehicles'],
        queryFn: () => getReportVehicles(),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    ],
  });

  const downloadButton: IFormSectionButton = {
    title: f({ id: 'reports.download' }),
    tooltip: f({ id: 'reports.download' }),
    disabled: props.buttonDisabled,
    buttonCallback: handleSubmit(onSubmit),
  };

  return (
    <div className="w-page new-css-wrapper" data-cy="reports-wrapper">
      <form
        className="form-horizontal"
        onSubmit={handleSubmit(onSubmit)}
        style={{ paddingTop: '30px' }}
        data-cy="reports-form"
      >
        <div className="container-fluid" data-cy="reports-container">
          <div data-cy="reports-sections">
            <div className="row" data-cy="reports-row">
              <div className="col-xxl-6" data-cy="reports-col">
                <Section
                  id="alarms-basic-information"
                  title={f({ id: 'alarms.section.basicInformation' })}
                  buttons={[downloadButton]}
                >
                  <Controller
                    name="reportId"
                    control={control}
                    rules={{ required: errors.reportId ? errors.reportId.message : undefined }}
                    render={({ field }) => (
                      <CmdSimpleSelect
                        {...field}
                        name="reportId"
                        id="reportId"
                        value={props.selectedReportId}
                        label={f({ id: 'reports.reportId' })}
                        introOption={f({ id: 'suppliersForm.choose' })}
                        options={props.reportsList}
                        required={true}
                        onChangeCallback={(value: string) => {
                          setValue('reportId', value);
                          props.handleReportId(value);
                        }}
                        error={errors.reportId?.message}
                        touched={true}
                      />
                    )}
                  />

                  <Controller
                    name="mergeBy"
                    control={control}
                    rules={{ required: errors.mergeBy ? errors.mergeBy.message : undefined }}
                    render={({ field }) => (
                      <CmdSimpleSelect
                        {...field}
                        name="mergeBy"
                        id="mergeBy"
                        label={f({ id: 'reports.mergeBy' })}
                        introOption={f({ id: 'form.choose' })}
                        options={[
                          { item: f({ id: 'reports.day' }), value: 'day' },
                          { item: f({ id: 'reports.week' }), value: 'week' },
                          { item: f({ id: 'reports.month' }), value: 'month' },
                          { item: f({ id: 'reports.year' }), value: 'year' },
                        ]}
                        required={true}
                        onChangeCallback={(value: string) => {
                          setValue('mergeBy', value);
                          trigger('mergeBy');
                        }}
                        error={errors.mergeBy?.message}
                        touched={true}
                      />
                    )}
                  />
                  <Controller
                    name="date"
                    control={control}
                    rules={{ required: errors.date ? errors.date.message : undefined }}
                    render={({ field }) => (
                      <Calendar
                        {...field}
                        id="date"
                        name="date"
                        label={f({ id: 'datepicker.range' })}
                        required={true}
                        allowedRange={handleAllowedRange(validation?.date)}
                        note="(dd.mm.rrrr hh:mm)"
                        mode="range"
                        disabledItems={['THIS_YEAR', 'PREVIOUS_YEAR']}
                        error={errors.date?.message}
                        touched={true}
                        onChangeCallback={(value: Date[]) => {
                          setValue('date', value);
                          trigger('date');
                        }}
                      />
                    )}
                  />
                  <Controller
                    name="format"
                    control={control}
                    rules={{ required: errors.format ? errors.format.message : undefined }}
                    render={({ field }) => (
                      <CmdRadioButton
                        {...field}
                        id="format"
                        name="format"
                        label={f({ id: 'reports.format' })}
                        options={[{ item: '.xlsx', value: 'xlsx' }]}
                        required={true}
                        disabled={true}
                      />
                    )}
                  />
                  <Controller
                    name="vehicleIds"
                    control={control}
                    rules={{ required: errors.vehicleIds ? errors.vehicleIds.message : undefined }}
                    render={({ field }) => (
                      <CmdChipSelect
                        {...field}
                        id="vehicleIds"
                        name="vehicleIds"
                        label={f({ id: 'reports.vehicleIds' })}
                        placeholder={f({ id: 'form.choose' })}
                        searchPlaceholder={f({ id: 'form.search' })}
                        selectButton={f({ id: 'form.groupChipSelectUnique.button.apply' })}
                        resetButton={f({ id: 'form.groupChipSelectUnique.button.reset' })}
                        options={vehicleOptions}
                        required={true}
                        searchTooltip={f({
                          id: 'alarms.section.vehicles.vehicleId.search.tooltip',
                        })}
                        selectButtonTooltip={f({
                          id: 'alarms.section.vehicles.vehicleId.selectButton.tooltip',
                        })}
                        resetButtonTooltip={f({
                          id: 'alarms.section.vehicles.vehicleId.resetButton.tooltip',
                        })}
                        bossCheckbox={f({ id: 'reports.chooseAllVehicles' })}
                        error={errors.vehicleIds?.message}
                        touched={true}
                        value={watch('vehicleIds')}
                        setValue={(value: string[]) => {
                          setValue('vehicleIds', value);
                          trigger('vehicleIds');
                        }}
                      />
                    )}
                  />
                </Section>
              </div>

              <div className="col-xxl-6" data-cy="reports-col">
                <CmdBox id="reports-sending-settings" title={f({ id: 'reports.reportSettings' })}>
                  {reportSettingsOptionsR07.length > 0 ? (
                    [
                      <CmdCheckbox
                        id="reportSelectAll"
                        name="reportSelectAll"
                        label={f({ id: 'alarms.modal.timeFrame.button.selectAll' })}
                        onChangeCallback={handleReportParameter}
                        value={bossCheckboxValue}
                      />,
                      <Controller
                        name="reportSettings"
                        control={control}
                        rules={{
                          required: errors.reportSettings
                            ? errors.reportSettings.message
                            : undefined,
                        }}
                        render={({ field }) => (
                          <CmdCheckboxGroup
                            {...field}
                            key="reportSettings"
                            id="reportSettings"
                            name="reportSettings"
                            value={watch('reportSettings')}
                            label={f({ id: 'reports.parametersSetting' })}
                            options={getReportSettingsOptions()}
                            gridTemplateColumns=" 1fr 1fr 1fr "
                            // bossCheckbox={f({ id: 'reports.chooseAll' })}
                            required={false}
                            setValue={(value: string[]) => {
                              setValue('reportSettings', value);
                              trigger('reportSettings');
                            }}
                            error={errors.reportSettings?.message}
                          />
                        )}
                      />,
                      <Controller
                        name="differentiation"
                        control={control}
                        rules={{
                          required: errors.differentiation
                            ? errors.differentiation.message
                            : undefined,
                        }}
                        render={({ field }) => (
                          <CmdCheckboxGroup
                            {...field}
                            key="differentiation"
                            id="differentiation"
                            name="differentiation"
                            value={watch('differentiation')}
                            label={f({ id: 'reports.section.parameterSettings.resolution' })}
                            options={getReportSettingsOptionsDifferentiation()}
                            gridTemplateColumns=" 1fr 1fr 1fr "
                            // bossCheckbox={f({ id: 'reports.chooseAll' })}
                            required={false}
                            setValue={(value: string[]) => {
                              setValue('differentiation', value);
                              trigger('differentiation');
                            }}
                          />
                        )}
                      />,
                    ]
                  ) : (
                    <CmdLabel
                      title={f({ id: 'reports.parametersSetting' })}
                      value={f({ id: 'reports.noParametersAvailable' })}
                      isLabelInTwoRows={true}
                    />
                  )}
                  <hr
                    style={{
                      margin: '-8px -24px 16px -24px',
                      borderTop: '1px solid #ddd',
                    }}
                  />
                  <Controller
                    name="consumptionType"
                    control={control}
                    rules={{
                      required: errors.consumptionType ? errors.consumptionType.message : undefined,
                    }}
                    render={({ field }) => (
                      <CmdRadioButton
                        {...field}
                        id="consumptionType"
                        name="consumptionType"
                        label={f({ id: 'reports.consumptionSettings' })}
                        options={[
                          {
                            item: f({ id: 'reports.refuelingsConsumption' }),
                            value: 'refueling',
                          },
                          { item: f({ id: 'reports.can' }), value: 'can' },
                        ]}
                        required={true}
                        customrGrid="1fr 1fr 1fr"
                        disabled={
                          !watch('reportSettings').includes('consumption') &&
                          !watch('reportSettings').includes('ridePrice')
                        }
                        setValue={(value: string) => {
                          setValue('consumptionType', value);
                        }}
                        supportMessage={f({
                          id: 'reports.parametersSetting.consumptionSettings.supportMessage',
                        })}
                      />
                    )}
                  />
                  <hr
                    style={{
                      margin: '-8px -24px 16px -24px',
                      borderTop: '1px solid #ddd',
                    }}
                  />
                  <Controller
                    name="showEmpty"
                    control={control}
                    rules={{
                      required: errors.showEmpty ? errors.showEmpty.message : undefined,
                    }}
                    render={({ field }) => (
                      <CmdSwitch
                        {...field}
                        id="showEmpty"
                        label={f({ id: 'reports.showEmpty' })}
                        value={watch('showEmpty')}
                        setValue={(value: number) => {
                          setValue('showEmpty', value);
                          trigger('showEmpty');
                        }}
                        note={f({ id: 'reports.section.showEmpty.tooltip' })}
                      />
                    )}
                  />
                </CmdBox>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
