import { useQueryClient, useSuspenseQueries } from '@tanstack/react-query';
import { useRecoilState, useSetRecoilState } from 'recoil';
import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useLocation } from 'wouter';
import { GdprProfilesResponse, PermissionsProfilesResponse, UserResponse } from './types';
import {
  getDefaultCustomer,
  getLanguage,
  getUserId,
  logOut,
  setLanguage,
  setUserData,
} from '../../Services/UserService';
import { ICustomer } from '../../interfaces/customer';
import { getTranslation, ITranslation } from '../../Services/LanguageService';
import { gdprSettingsAtom, localeStringsAtom } from '../../store/recoil/AppState';
import {
  getGdprProfiles,
  getCustomers,
  getUserData,
  setPermissions,
  createCustomersArray,
  createCustomerIdsArray,
  removeUnusedCustomersFromDefaultCustomers,
} from './UserDataService';
import {
  getAuthTokenTimeout,
  getRefreshToken,
  getRefreshTokenRequest,
} from './RefreshTokenService';
import {
  setAuthToken,
  setAuthTokenExpiresAt,
  setRefreshToken,
  setRefreshTokenExpiresAt,
  setSocketServerToken,
} from '../../Services/LoginService';
import LoaderService from '../../Services/LoaderService';
import configJs from '../../../config';
import {
  IVehicle,
  IVehicleGroup,
  IVehicles,
  IVehiclesData,
  VehiclesBasicInfo,
} from '../Vehicles/types';
import { IOption } from '../Forms/CmdField';
import * as WidgetState from '../../store/recoil/widgets';
import { IOnlinePanelData } from '../OnlineMapNew/PanelsLayer/interfaces';
import {
  IVehicleOnlinePanels,
  onlineMapTemplatesAtom,
  onlinePanelAtom,
} from '../OnlinePanel/OnlinePanelState';
import { EVENT_USER_VEHICLES } from '../SocketConnection/constants';
import { localForageStore } from '../../store/localForageStore';
import { SocketConnectionWorker } from '../SocketConnection';
import { IWidget } from '../OnlinePanel/Widgets/types';
import {
  selectedVehiclesAtom,
  vehicleGroupsAtom,
  vehiclesAtom,
  vehiclesBasicInfoAtom,
  vehiclesOptionsAtom,
} from '../../store/recoil/vehicles';
import { vehiclesDataAtom } from '../../store/recoil/vehiclesData';
import {
  getVehiclesCustomerId,
  vehicleArrayToObject,
  vehicleDataArrayToObject,
  vehicleOnlinePanelArrayToObject,
} from '../../Services/VehicleService';
import {
  customersAtom,
  selectedCustomersAtom,
  selectedTempCustomersAtom,
} from '../../store/recoil/customers';
import { getVehicleGroups, getVehicles } from '../Navigator/NavigatorService';
import {
  activeMenuIdAtom,
  activeMenuParentIdAtom,
  carRentalAtom,
  menuItemsAtom,
  urlAtom,
} from '../MainMenu/MainMenuState';
import { getMenuItems } from '../MainMenu/MainMenuService';

const REFRESH_TOKEN_TIMEOUT = 120;

const UserData = (): null => {
  const [_, setNavigate] = useLocation();
  const queryClient = useQueryClient();
  const setGdprSettings = useSetRecoilState(gdprSettingsAtom);
  const setSelectedCustomers = useSetRecoilState<number[]>(selectedCustomersAtom);
  const setCustomers = useSetRecoilState<ICustomer[]>(customersAtom);
  const setSelectedTempCustomers = useSetRecoilState<number[]>(selectedTempCustomersAtom);
  const setLocaleStrings = useSetRecoilState<ITranslation>(localeStringsAtom);
  const setCarRental = useSetRecoilState(carRentalAtom);
  const setMenuItems = useSetRecoilState(menuItemsAtom);
  const [activeMenuId, setMenuId] = useRecoilState<number>(activeMenuIdAtom);
  const [activeMenuParentId, setActiveMenuParentId] =
    useRecoilState<number>(activeMenuParentIdAtom);
  const setUrl = useSetRecoilState<string>(urlAtom);
  const setVehicleGroups = useSetRecoilState<IVehicleGroup[]>(vehicleGroupsAtom);
  const setVehiclesOptions = useSetRecoilState<IOption[]>(vehiclesOptionsAtom);
  const setVehicles = useSetRecoilState<IVehicles>(vehiclesAtom);
  const setVehiclesDataState = useSetRecoilState<IVehiclesData>(vehiclesDataAtom);
  const setUsedWidgets = useSetRecoilState<IWidget[]>(WidgetState.usedWidgets);
  const setOnlineMapTemplates = useSetRecoilState<IOnlinePanelData[]>(onlineMapTemplatesAtom);
  const setOnlinePanel = useSetRecoilState<IVehicleOnlinePanels>(onlinePanelAtom);
  const setSelectedVehicles = useSetRecoilState(selectedVehiclesAtom);
  const setVehicleBasicInfo = useSetRecoilState<VehiclesBasicInfo>(vehiclesBasicInfoAtom);

  const signInQueryData = queryClient.getQueryData<AxiosResponse | false>(['signIn']);

  const [userDataUpdatedAtTimestamp, setUserDataUpdatedAtTimestamp] = useState<number>(0);
  const [gdprProfilesUpdatedAtTimestamp, setGdprProfilesUpdatedAtTimestamp] = useState<number>(0);
  const [customersDataUpdatedAtTimestamp, setCustomersDataUpdatedAtTimestamp] = useState<number>(0);
  const [menuDataUpdatedAtTimestamp, setMenuDataUpdatedAtTimestamp] = useState<number>(0);
  const [vehicleGroupsDataUpdatedAtTimestamp, setVehicleGroupsDataUpdatedAtTimestamp] =
    useState<number>(0);
  const [vehiclesDataUpdatedAtTimestamp, setVehiclesDataUpdatedAtTimestamp] = useState<number>(0);

  const [isRefreshTokenFetched, setIsRefreshTokenFetched] =
    React.useState<boolean>(!!signInQueryData);

  const [
    userDataQuery,
    userGdprProfilesQuery,
    customersQuery,
    menuQuery,
    vehicleGroupsQuery,
    vehiclesQuery,
  ] = useSuspenseQueries({
    queries: [
      {
        queryKey: ['userData'],
        queryFn: () => getUserData(),
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['userGdprProfiles'],
        queryFn: () => getGdprProfiles(),
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['customers'],
        queryFn: () => getCustomers(),
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['userMenu'],
        queryFn: () => getMenuItems(),
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['vehicleGroups'],
        queryFn: () => getVehicleGroups(),
        staleTime: 9000,
        gcTime: 0,
      },
      {
        queryKey: ['vehicles'],
        queryFn: () => getVehicles(),
        refetchOnWindowFocus: true,
        refetchOnReconnect: false,
        staleTime: 9000,
        gcTime: 0,
      },
    ],
  });

  const { data: userData, dataUpdatedAt: userDataUpdatedAt } = userDataQuery;
  const { data: gdprProfilesData, dataUpdatedAt: gdprProfilesUpdatedAt } = userGdprProfilesQuery;
  const {
    data: customersData,
    dataUpdatedAt: customersDataUpdatedAt,
    isLoading: isLoadingCustomers,
  } = customersQuery;
  const { data: menuData, dataUpdatedAt: menuDataUpdatedAt } = menuQuery;
  const { data: vehicleGroupsData, dataUpdatedAt: vehicleGroupsDataUpdatedAt } = vehicleGroupsQuery;
  const {
    data: vehiclesData,
    isLoading: isVehiclesLoading,
    dataUpdatedAt: vehiclesDataUpdatedAt,
  } = vehiclesQuery;

  const reloadLanguage = useCallback(
    async (language: string) => {
      try {
        const data = await queryClient.fetchQuery({
          queryKey: ['translations', language],
          queryFn: () => getTranslation(language),
        });
        if (data) {
          setLocaleStrings(data);
        }
      } catch (error) {
        console.error('Error fetching translations', error);
      }
    },
    [queryClient, setLocaleStrings]
  );

  const fetchRefreshToken = useCallback(async () => {
    const refreshToken = getRefreshToken();
    const refreshTokenData = await queryClient.fetchQuery({
      queryKey: ['refreshToken'],
      queryFn: () => getRefreshTokenRequest(refreshToken),
      staleTime: 10000,
    });
    if (refreshTokenData) {
      // console.log('Save new auth token: ', refreshTokenData.authToken);
      setAuthToken(refreshTokenData.authToken);
      setAuthTokenExpiresAt(refreshTokenData.authTokenExpiresAt);
      setRefreshToken(refreshTokenData.refreshToken);
      setRefreshTokenExpiresAt(refreshTokenData.refreshTokenExpiresAt);
      setSocketServerToken(refreshTokenData.socketServerToken);
      setIsRefreshTokenFetched(true);
    }
  }, [queryClient]);

  const fetchRefreshTokenWithTimeout = useCallback(() => {
    if (getAuthTokenTimeout() <= REFRESH_TOKEN_TIMEOUT) {
      fetchRefreshToken();
    }
  }, [fetchRefreshToken]);

  useEffect(() => {
    const timer = setInterval(fetchRefreshTokenWithTimeout, 5000);
    window.addEventListener('visibilitychange', fetchRefreshTokenWithTimeout);
    return () => {
      clearInterval(timer);
      window.removeEventListener('visibilitychange', fetchRefreshTokenWithTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isRefreshTokenFetched) {
      fetchRefreshToken();
    }
    if (isRefreshTokenFetched && !isVehiclesLoading && !isLoadingCustomers) {
      LoaderService.showNavigatorLoader(false);
    } else {
      LoaderService.showNavigatorLoader();
    }
    if (
      isRefreshTokenFetched &&
      vehicleGroupsData &&
      // isLoadingVehicleGroups &&
      vehicleGroupsDataUpdatedAt > vehicleGroupsDataUpdatedAtTimestamp
    ) {
      console.info('Vehicle groups loaded.');
      setVehicleGroups(vehicleGroupsData);
      // setIsLoadingVehicleGroups(false);
      setVehicleGroupsDataUpdatedAtTimestamp(vehicleGroupsDataUpdatedAt);
    }
    if (
      isRefreshTokenFetched &&
      vehiclesData &&
      vehiclesDataUpdatedAt > vehiclesDataUpdatedAtTimestamp
    ) {
      console.info('Vehicles loaded.');
      const vehiclesOptions: IOption[] = Object.values(vehiclesData.vehicles as IVehicle[]).map(
        (i: IVehicle) => {
          return { value: String(i.id), item: `${i.licenceNumber} - ${i.name}` };
        }
      );
      const vehiclesBasicInfo: VehiclesBasicInfo = {};
      vehiclesData.vehicles.forEach((i: IVehicle) => {
        vehiclesBasicInfo[i.id] = {
          id: i.id,
          name: i.name,
          licenceNumber: i.licenceNumber,
          vehicleTypeId: i.vehicleTypeId,
          customerId: i.customerId,
        };
      });
      setVehiclesOptions(vehiclesOptions);
      setVehicles(vehicleArrayToObject(vehiclesData.vehicles));
      setVehiclesDataState(vehicleDataArrayToObject(vehiclesData.vehiclesData));
      setUsedWidgets(vehiclesData.usedWidgets);
      setOnlineMapTemplates(vehiclesData.onlineMapTemplate);
      setOnlinePanel(vehicleOnlinePanelArrayToObject(vehiclesData.onlinePanelData));
      setVehicleBasicInfo(vehiclesBasicInfo);

      const defaultCustomers = getDefaultCustomer();
      if (defaultCustomers.length === 1) {
        const vehiclesByCustomer = getVehiclesCustomerId(
          vehicleArrayToObject(vehiclesData.vehicles),
          [],
          defaultCustomers[0]
        );
        if (vehiclesByCustomer.length === 1) {
          if (vehiclesByCustomer[0]) {
            setSelectedVehicles([vehiclesByCustomer[0].id]);
          }
        }
      }
      // Send user vehicles to SocketServer
      const vehicleIds = vehiclesData.vehicles.map((vehicle: IVehicle) => vehicle.id);
      const data = {
        event: EVENT_USER_VEHICLES,
        payload: vehicleIds,
        userId: getUserId(),
      };
      SocketConnectionWorker.postMessage(data);
      localForageStore.setItem('vehicleIds', vehicleIds);

      setVehiclesDataUpdatedAtTimestamp(vehiclesDataUpdatedAt);
    }
    if (
      !signInQueryData &&
      isRefreshTokenFetched &&
      gdprProfilesData &&
      gdprProfilesUpdatedAt > gdprProfilesUpdatedAtTimestamp
    ) {
      console.info('Gdpr profiles loaded.');
      setGdprSettings(gdprProfilesData as GdprProfilesResponse);
      setGdprProfilesUpdatedAtTimestamp(gdprProfilesUpdatedAt);
    }
    if (
      !signInQueryData &&
      isRefreshTokenFetched &&
      userData &&
      userDataUpdatedAt > userDataUpdatedAtTimestamp
    ) {
      console.info('User data loaded.');
      setUserData(userData as UserResponse);
      setSelectedCustomers((userData as UserResponse).selectedCustomers);
      const language = (userData as UserResponse).language;
      if (language !== getLanguage()) {
        setLanguage(language);
        reloadLanguage(language);
      }
      setUserDataUpdatedAtTimestamp(userDataUpdatedAt);
    }
    if (
      !signInQueryData &&
      isRefreshTokenFetched &&
      customersData &&
      customersDataUpdatedAt > customersDataUpdatedAtTimestamp
    ) {
      console.info('Customers with permissions loaded.');
      setPermissions(customersData as PermissionsProfilesResponse);
      // setPermissionsRecoil(permissionsData as PermissionsProfilesResponse);

      const customers: ICustomer[] = createCustomersArray(
        customersData as PermissionsProfilesResponse
      );

      if (customers.length > 0) {
        const customerIds: number[] = createCustomerIdsArray(customers);

        // Remove unused customerIds from defaultCustomers, selectedCustomers and selectedTempCustomers
        const newDefaultCustomers = removeUnusedCustomersFromDefaultCustomers(customerIds);

        setSelectedCustomers(
          newDefaultCustomers.filter((customerId: number) => customerIds.includes(customerId))
        );
        setSelectedTempCustomers(
          newDefaultCustomers.filter((customerId: number) => customerIds.includes(customerId))
        );

        setCustomers(customers); // recoil
      }
      setCustomersDataUpdatedAtTimestamp(customersDataUpdatedAt);
    }
    if (isRefreshTokenFetched && menuData && menuDataUpdatedAt > menuDataUpdatedAtTimestamp) {
      console.info('Menu loaded.');
      if (!menuData || menuData?.panel?.items?.length === 0) {
        logOut();
        console.warn('Empty menu items. Logging out. ');
      }

      const menu = menuData.panel || [];
      if (menuData.carRental && menuData.carRental.enabled) {
        setCarRental(menuData.carRental.url);
      }
      if (menu && menu.items && menu.items.length > 0) {
        setMenuItems(menu.items);

        if (
          activeMenuId === 1 &&
          // menu.items.find((item) => item.id === activeMenuId) &&
          activeMenuParentId === 0 &&
          (menu.items[0].items || menu.items[0].link !== configJs.Login.homeUrl)
        ) {
          if (!menu.items[0].items) {
            setMenuId(menu.items[0].id);
            setActiveMenuParentId(0);
            setUrl(menu.items[0].link);
            setNavigate(menu.items[0].link);
          } else {
            setMenuId(menu.items[0].items[Object.keys(menu.items[0].items)[0]].id);
            setActiveMenuParentId(menu.items[0].id);
            setUrl(menu.items[0].items[Object.keys(menu.items[0].items)[0]].link);
            setNavigate(menu.items[0].items[Object.keys(menu.items[0].items)[0]].link);
          }
        }
      }
      setMenuDataUpdatedAtTimestamp(menuDataUpdatedAt);
    }
  }, [
    gdprProfilesData,
    customersData,
    reloadLanguage,
    setCustomers,
    setGdprSettings,
    setSelectedCustomers,
    setSelectedTempCustomers,
    userData,
    isRefreshTokenFetched,
    menuData,
    isVehiclesLoading,
    vehicleGroupsData,
    vehiclesData,
    signInQueryData,
    setVehicleGroups,
    setVehiclesOptions,
    setVehicles,
    setVehiclesDataState,
    setUsedWidgets,
    setOnlineMapTemplates,
    setOnlinePanel,
    setSelectedVehicles,
    setCarRental,
    setMenuItems,
    activeMenuId,
    activeMenuParentId,
    setMenuId,
    setActiveMenuParentId,
    setNavigate,
    setUrl,
    userDataUpdatedAt,
    userDataUpdatedAtTimestamp,
    customersDataUpdatedAt,
    customersDataUpdatedAtTimestamp,
    menuDataUpdatedAt,
    menuDataUpdatedAtTimestamp,
    fetchRefreshToken,
    vehicleGroupsDataUpdatedAt,
    vehicleGroupsDataUpdatedAtTimestamp,
    vehiclesDataUpdatedAt,
    vehiclesDataUpdatedAtTimestamp,
    gdprProfilesUpdatedAt,
    gdprProfilesUpdatedAtTimestamp,
    isLoadingCustomers,
    setVehicleBasicInfo,
  ]);
  return null;
};

export default UserData;
