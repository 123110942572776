import dayjs from 'dayjs';
import FormikValidatorService, {
  IValidationApiResponse,
} from '../../Services/FormikValidatorService';
import {
  CurrencyIdApiResponse,
  RefuelingApiResponse,
  RefuelingsFormValues,
  VatApiResponse,
} from './types';
import { IOption } from '../Forms/CmdField';
import HookFormService from '../../Services/HookFormService';
import { deleteRequest, getRequest, patchRequest, postRequest } from '../../Services/HttpService';

export const convertDateValue = (item: string): string => {
  let newItem = '';
  const regexFindDate = /[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}/gi;
  if (regexFindDate.test(item)) {
    const [date, ...info] = item.split(' - ');
    if (date) {
      const dateAfterSplit = date.split(' ');
      if (dateAfterSplit && dateAfterSplit.length === 2) {
        const newDateString = `${dateAfterSplit[0]}T${dateAfterSplit[1]}Z`;
        const regexFindDateString = /[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}Z/gi;
        if (regexFindDateString.test(newDateString)) {
          const formattedDate = dayjs(newDateString).format('DD.MM.YYYY HH:mm');
          newItem = info ? `${formattedDate} - ${info.join(' - ')}` : formattedDate;
        }
      }
    }
  }
  return newItem || item;
};

export const getValidation = async (): Promise<IValidationApiResponse | false> => {
  const url = '/v2/refueling/validation';
  const response = await getRequest<IValidationApiResponse>(url);
  if (response) {
    return response as IValidationApiResponse;
  }
  return false;
};

export const getRidesByVehicleId = async (
  customerId: number | null,
  vehicleId: number,
  accountingTs?: string | null
): Promise<IOption[] | false> => {
  const localStorageCustomers = localStorage.getItem('defaultCustomer');
  const defaultCustomer = JSON.parse(localStorageCustomers || '');
  if (!localStorageCustomers && !customerId) {
    console.warn('getFormField: Missing customerId.');
    return false;
  }
  if (!customerId && !Array.isArray(defaultCustomer)) {
    console.warn('getFormField: Missing customerId.');
    return false;
  }
  if (!customerId && Array.isArray(defaultCustomer) && defaultCustomer.length === 0) {
    console.warn('getFormField: Missing customerId.');
    return false;
  }
  const custId = customerId || defaultCustomer[0];
  let url = `/v2/refueling/form/rideId?customerId=${custId}&vehicleId=${vehicleId}`;
  if (accountingTs) {
    url = `/v2/refueling/form/rideId?customerId=${custId}&vehicleId=${vehicleId}&accountingTs=${accountingTs}`;
  }
  const response = await getRequest(url);
  if (response) {
    return response as IOption[];
  }
  return false;
};

export const getCurrencyByCustomerId = async (
  customerId: number
): Promise<CurrencyIdApiResponse | false> => {
  const url = `/v2/refueling/form/currencyId?customerId=${customerId}`;
  const response = await getRequest(url);
  if (response) {
    return response as CurrencyIdApiResponse;
  }
  return false;
};

export const getVatByCustomerId = async (customerId: number): Promise<VatApiResponse | false> => {
  const url = `/v2/refueling/form/vat?customerId=${customerId}`;
  const response = await getRequest(url);
  if (response) {
    return response as VatApiResponse;
  }
  return false;
};

export const getFormField = async (
  field: string,
  customerId: number | null
): Promise<IOption[] | false> => {
  const localStorageCustomers = localStorage.getItem('defaultCustomer');
  const defaultCustomer = JSON.parse(localStorageCustomers || '');
  if (!localStorageCustomers && !customerId) {
    console.warn('getFormField: Missing customerId.');
    return false;
  }
  if (!customerId && !Array.isArray(defaultCustomer)) {
    console.warn('getFormField: Missing customerId.');
    return false;
  }
  if (!customerId && Array.isArray(defaultCustomer) && defaultCustomer.length === 0) {
    console.warn('getFormField: Missing customerId.');
    return false;
  }
  const custId = customerId || defaultCustomer[0];
  const url = `/v2/refueling/form/${field}?customerId=${custId}`;
  const response = await getRequest(url);
  if (response) {
    return response as IOption[];
  }
  return false;
};

export const save = async (values: RefuelingsFormValues): Promise<RefuelingApiResponse | false> => {
  const url = '/v2/refueling';
  const response = await postRequest(url, values);
  if (response) {
    return response as RefuelingApiResponse;
  }
  return false;
};

export const get = async (
  id: number | string | undefined,
  customerId: number | null
): Promise<RefuelingApiResponse | false> => {
  if (!id || !customerId) {
    console.warn('Missing id or customerId or both.');
    return false;
  }
  const url = `/v2/refueling/${id}?customerId=${customerId}`;
  const response = await getRequest<RefuelingApiResponse>(url);
  if (response) {
    return response as RefuelingApiResponse;
  }
  return false;
};

export const remove = async (id: number): Promise<any> => {
  const url = `v2/refueling/${id}`;
  const response = await deleteRequest(url);
  if (response) {
    return response;
  }
  return false;
};

export const update = async (id: number, values): Promise<any> => {
  const url = `v2/refueling/${id}`;
  const response = await patchRequest(url, values);
  if (response) {
    return response;
  }
  return false;
};

export const getLabelValue = (options: IOption[], value: string | number): string | null => {
  const option = options.find((i: IOption) => i.value === value);
  if (option) {
    return option.item;
  }
  return null;
};

export const convertDateInRides = (options: IOption[]) => {
  if (options) {
    return options.map((option: IOption) => {
      return { item: convertDateValue(option.item), value: option.value };
    });
  }
  return [];
};

export const validateFields = (
  validation: IValidationApiResponse | false | undefined,
  values: RefuelingsFormValues
) => {
  let formErrors = {};
  if (validation) {
    formErrors = FormikValidatorService.validateByValidationApiResponse(values, validation);
  }
  return HookFormService.transformErrors(formErrors);
};
