import { useMatomo } from '@datapunt/matomo-tracker-react';
import { TrackEventParams } from '@datapunt/matomo-tracker-react/lib/types';

type UseTrackEventParams = [string, string, string, number?];
type UseTrackEvent = (params: UseTrackEventParams) => void;

const useTrackEvent = (): UseTrackEvent => {
  const { trackEvent } = useMatomo();

  const trackFn = (params: UseTrackEventParams) => {
    const [category, action, name, value] = params;
    const trackEventParams: TrackEventParams = { category, action, name, value };
    trackEvent(trackEventParams);
  };

  return trackFn;
};

export default useTrackEvent;
