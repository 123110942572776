/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useIntl } from 'react-intl';
import { CmdLabel, CmdRadioButton, CmdSimpleSelect } from '@commander-services/gui-components';
import CmdBox from '@commander-services/cmd-box';
import { Controller, useForm } from 'react-hook-form';
import FormikValidatorService from '../../Services/FormikValidatorService';
import Section, { IFormSectionButton } from '../Forms/Section';
import usePageWithForm from '../../hooks/usePageWithForm';
import Calendar from '../Forms/ReactHookFormFields/Calendar';
import HookFormService from '../../Services/HookFormService';
import { IReportItem } from './types';

interface IReportInputForm {
  reportId: string;
  format: string;
  date: Date[];
}

interface IReportForm {
  handleReportId: (id: string) => void;
  reportsList: IReportItem[];
  buttonDisabled: boolean;
}

export default function Form(props: IReportForm) {
  usePageWithForm();
  const { formatMessage: f } = useIntl();
  const parseDateToday0 = new Date(new Date().setHours(0, 0, 0, 0));
  const parseDateToday24 = new Date(new Date().setHours(23, 59, 0, 0));

  const validateFields = (values: any) => {
    let formErrors = {};
    formErrors = FormikValidatorService.validateByValidationApiResponse(values, {
      reportId: [
        {
          param: 'required',
          value: true,
          message: f({ id: 'form.required' }),
        },
      ],
    });
    return HookFormService.transformErrors(formErrors);
  };

  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<IReportInputForm>({
    mode: 'onChange',
    defaultValues: {
      reportId: '',
      format: 'xlsx',
      date: [parseDateToday0, parseDateToday24],
    },
    resolver: async (data) => {
      const formErrors = validateFields(data);
      return {
        values: formErrors && Object.keys(formErrors).length ? {} : data, // if there's an error, return empty values
        errors: formErrors,
      };
    },
  });

  const onSubmit = () => {
    return true;
  };

  const downloadButton: IFormSectionButton = {
    title: f({ id: 'reports.download' }),
    tooltip: f({ id: 'reports.download' }),
    disabled: props.buttonDisabled,
    buttonCallback: handleSubmit(onSubmit),
  };

  return (
    <div className="w-page" data-cy="reports-wrapper">
      <form
        className="form-horizontal"
        onSubmit={handleSubmit(onSubmit)}
        style={{ paddingTop: '30px' }}
        data-cy="reports-form"
      >
        <div className="container-fluid" data-cy="reports-container">
          <div data-cy="reports-sections">
            <div className="row" data-cy="reports-row">
              <div className="col-xxl-6" data-cy="reports-col">
                <Section
                  id="alarms-basic-information"
                  title={f({ id: 'alarms.section.basicInformation' })}
                  buttons={[downloadButton]}
                >
                  <Controller
                    name="reportId"
                    control={control}
                    rules={{ required: errors.reportId ? errors.reportId.message : undefined }}
                    render={({ field }) => (
                      <CmdSimpleSelect
                        {...field}
                        name="reportId"
                        id="reportId"
                        label={f({ id: 'reports.reportId' })}
                        introOption={f({ id: 'suppliersForm.choose' })}
                        options={props.reportsList}
                        required={true}
                        error={errors.reportId?.message}
                        touched={true}
                        onChangeCallback={(value: string) => {
                          setValue('reportId', value);
                          props.handleReportId(value);
                        }}
                      />
                    )}
                  />
                  <Controller
                    name="date"
                    control={control}
                    render={({ field }) => (
                      <Calendar
                        {...field}
                        id="date"
                        label={f({ id: 'datepicker.range' })}
                        required={true}
                        calendarTranslations={{}}
                        // dateFormat="MM/yyyy"
                        onChangeCallback={(values) => {
                          setValue('date', values as Date[]);
                          trigger('date');
                        }}
                        allowedRange={undefined}
                        note="(dd.mm.rrrr hh:mm)"
                        mode="range"
                        error={errors.date?.message}
                        touched={true}
                      />
                    )}
                  />
                  <Controller
                    name="format"
                    control={control}
                    render={({ field }) => (
                      <CmdRadioButton
                        {...field}
                        id="format"
                        name="format"
                        label={f({ id: 'reports.format' })}
                        options={[{ item: '.xlsx', value: 'xlsx' }]}
                        required={true}
                        disabled={true}
                      />
                    )}
                  />
                </Section>
              </div>

              <div className="col-xxl-6" data-cy="reports-col">
                <CmdBox id="reports-sending-settings" title={f({ id: 'reports.reportSettings' })}>
                  <CmdLabel
                    title={f({ id: 'reports.parametersSetting' })}
                    value={f({ id: 'reports.parametersInfo' })}
                    isLabelInTwoRows={true}
                  />
                </CmdBox>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
