import * as React from 'react';
import { useIntl } from 'react-intl';
import { ICustomWidgetProps } from './Widget';
import RemoveButton from './RemoveButton';

export default function TachowebWidget(props: ICustomWidgetProps): JSX.Element {
  const { formatMessage: f } = useIntl();

  const onDragStart = props.isInGrid ? undefined : props.onDragCallback;

  const handleOnClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    const url = `https://ddd.teltonika.lt/Account/Login`;
    window.open(url, '_blank');
  };

  const handleWidgetTooltip = () => {
    if (props.data.columnSize === 1 && props.data.rowSize === 1 && !props.isMockupMode) {
      return f({ id: 'widget.tachoweb.tooltip' });
    }
    if (!props.isInGrid && props.isMockupMode) {
      return f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' });
    }
    return '';
  };

  return (
    <div
      data-cy="tachoweb-widget"
      className={`b-widget is-clickable b-widget--${props.data.rowSize}x${props.data.columnSize}
      widget-grid-name-${props.data.gridName} ${props.isInGrid ? '' : 'droppable-element'}`}
      draggable={!props.isInGrid}
      onDragStart={onDragStart}
      title={handleWidgetTooltip()}
      style={{ position: 'relative', cursor: 'pointer' }}
      onClick={
        props.data.columnSize === 1 && props.data.rowSize === 1 && !props.isMockupMode
          ? handleOnClick
          : undefined
      }
    >
      {props.isInGrid && (
        <RemoveButton
          dataCy="tachoweb-widget-in-grid"
          onRemoveCallback={props.onRemoveCallback}
          gridName={props.data.gridName}
        />
      )}
      {props.data.columnSize === 1 && props.data.rowSize === 1 && (
        <>
          <i
            data-cy="tachoweb-widget-icon-size-1"
            className="e-widget-icon cmd-icon cmd-icon-tachograph"
          />
          <strong style={{ marginTop: '1rem' }}>Tachoweb</strong>
        </>
      )}
    </div>
  );
}
