import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  isOnlinePanelOpenAtom,
  IVehicleOnlinePanels,
  IWidgetGraph,
  onlinePanelAtom,
} from '../OnlinePanelState';
import { ICustomWidgetProps } from './Widget';
import * as VehiclesDataState from '../../../store/recoil/vehiclesData';
import { IVehicleData } from '../../Vehicles/types';
import Config from '../../../../config';
import { ordinaryWidgetGraphAtom } from '../../Graphs/GraphState';
import RemoveButton from './RemoveButton';
import { getWidgetTooltip, getWidgetValue, isWidgetDisabled } from './WidgetService';

export const BATTERY_VOLTAGE = 'batteryVoltage';

export default function BatteryWidget(props: ICustomWidgetProps): JSX.Element {
  const isGraphEnabled = Config.widgets.BatteryWidget.enableGraph;
  const { formatMessage: f } = useIntl();
  const [widgetData, setWidgetData] = React.useState<number | null>(0);
  const valueTypeShort = 'V';
  const vehicleId = useRecoilValue<number | false>(isOnlinePanelOpenAtom);
  const onlinePanelData = useRecoilValue<IVehicleOnlinePanels>(onlinePanelAtom);
  const shortName = f({ id: 'widget.battery.valueName.short' });
  const longName = f({ id: 'widget.battery.valueName.long' });
  const LOW_VOLTAGE = 11;
  const vehicleData = useRecoilValue<IVehicleData | null>(
    VehiclesDataState.vehicleDataByIdSelector(vehicleId)
  );
  const setOrdinaryWidgetGraph = useSetRecoilState<IWidgetGraph | null>(ordinaryWidgetGraphAtom);

  React.useMemo(() => {
    if (!props.isMockupMode) {
      const onlinePanel = onlinePanelData[Number(vehicleId)];

      if (onlinePanel && onlinePanel.battery !== null) {
        const roundedValue = Number(onlinePanel.battery).toFixed(2);
        setWidgetData(Number(roundedValue));
      } else {
        setWidgetData(null);
      }
    }
  }, [onlinePanelData, props.isMockupMode, vehicleId]);

  const handleDetail = () => {
    if (isGraphEnabled && typeof widgetData === 'number' && vehicleData) {
      // const rideId = vehicleData.lastRide?.id;
      setOrdinaryWidgetGraph({
        vehicleId: vehicleId || 0,
        name: BATTERY_VOLTAGE,
      });
    }
  };

  return (
    <div
      onClick={props.isMockupMode ? undefined : handleDetail}
      style={props.isMockupMode ? undefined : isGraphEnabled ? { cursor: 'pointer' } : {}}
      data-cy="battery-widget"
      className={`b-widget is-clickable b-widget--${props.data.rowSize}x${props.data.columnSize} ${
        props.data.hasIcon && props.data.columnSize > 1 ? 'b-widget--alignLeft' : ''
      } widget-grid-name-${props.data.gridName} ${props.isInGrid ? '' : 'droppable-element'}`}
      draggable={!props.isInGrid}
      onDragStart={props.isInGrid ? undefined : props.onDragCallback}
      title={getWidgetTooltip(
        f({ id: 'widget.battery.tooltip' }),
        f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' }),
        props.isInGrid,
        props.isMockupMode
      )}
    >
      {props.isInGrid && (
        <RemoveButton
          dataCy="battery-widget-in-grid"
          onRemoveCallback={props.onRemoveCallback}
          gridName={props.data.gridName}
        />
      )}
      {props.data.hasTitle && props.data.columnSize === 1 && !props.data.hasIcon && (
        <h4 data-cy="battery-widget-title-size-1" className="e-widget-title">
          {shortName}
        </h4>
      )}
      {props.data.hasTitle && props.data.columnSize > 1 && !props.data.hasIcon && (
        <h4 data-cy="battery-widget-title-size-more-1 " className="e-widget-title">
          {longName}
        </h4>
      )}
      {props.data.hasTitle && props.data.columnSize === 2 && props.data.hasIcon && (
        <h4 data-cy="battery-widget-title-size-2" className="e-widget-title">
          {longName}
        </h4>
      )}
      {props.data.hasIcon && props.data.columnSize > 1 && (
        <div className="row no-gutters" data-cy="battery-widget-icon-size-more-1">
          <div className="col-auto">
            {props.data.columnSize === 2 && (
              <i
                className={`e-widget-icon cmd-icon cmd-icon-battery ${
                  widgetData !== null && !props.isMockupMode && widgetData < LOW_VOLTAGE
                    ? 'active'
                    : ''
                } ${isWidgetDisabled(widgetData)}`}
              />
            )}
            {props.data.columnSize === 3 && (
              <i
                className={`e-widget-icon e-widget-icon--md cmd-icon cmd-icon-battery ${
                  widgetData !== null && !props.isMockupMode && widgetData < LOW_VOLTAGE
                    ? 'active'
                    : ''
                } ${isWidgetDisabled(widgetData)}`}
              />
            )}
          </div>
          <div className="col">
            {props.data.hasTitle && props.data.columnSize === 3 && (
              <h4 className="e-widget-title">{longName}</h4>
            )}
            <div className={`e-widget-value e-widget-value--md ${isWidgetDisabled(widgetData)}`}>
              {getWidgetValue(widgetData, props.data.hasUnitType, valueTypeShort)}
            </div>
          </div>
        </div>
      )}
      {!props.data.hasIcon && props.data.columnSize > 1 && (
        <div
          data-cy="battery-widget-value-size-more-1"
          className={`e-widget-value e-widget-value--md ${isWidgetDisabled(widgetData)}`}
          title={`${longName} (${valueTypeShort})`}
        >
          {getWidgetValue(widgetData, props.data.hasUnitType, valueTypeShort)}
        </div>
      )}
      {props.data.hasIcon && props.data.columnSize === 1 && (
        <i
          data-cy="battery-widget-icon-size-1"
          className={`e-widget-icon cmd-icon cmd-icon-battery ${
            widgetData !== null && !props.isMockupMode && widgetData < LOW_VOLTAGE ? 'active' : ''
          } ${isWidgetDisabled(widgetData)}`}
        />
      )}
      {props.data.columnSize === 1 && (
        <div
          data-cy="battery-widget-value-size-1"
          className={`e-widget-value ${isWidgetDisabled(widgetData)}`}
        >
          {getWidgetValue(widgetData, props.data.hasUnitType, valueTypeShort)}
        </div>
      )}
    </div>
  );
}
