import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  isOnlinePanelOpenAtom,
  IVehicleOnlinePanels,
  IWidgetGraph,
  onlinePanelAtom,
} from '../OnlinePanelState';
import { ICustomWidgetProps } from './Widget';
import { evBatteryKwWidgetGraphAtom, evBatteryPercWidgetGraphAtom } from '../../Graphs/GraphState';
import RemoveButton from './RemoveButton';
import { getWidgetTooltip, getWidgetValue } from './WidgetService';

export const LOW_BATTERY_LEVEL = 40;
export const MIDDLE_BATTERY_LEVEL = 95;
export const BATTERY_LEVEL_95 = 95;
export const BATTERY_LEVEL_80 = 80;
export const BATTERY_LEVEL_60 = 60;
export const BATTERY_LEVEL_40 = 40;
export const BATTERY_LEVEL_20 = 20;
export const EV_BATTERY_REMAINING = 'ev_battery_remaining';
export const EV_BATTERY_RANGE = 'ev_battery_range';
export const EV_BATTERY_PERCENT = 'ev_battery_percent';
export const EV_BATTERY = 'ev_battery';

export default function ElectroBatteryWidget(props: ICustomWidgetProps): JSX.Element {
  const { formatMessage: f } = useIntl();
  const [widgetData, setWidgetData] = React.useState<{
    percent: number | null;
    value: number;
  } | null>({
    percent: 50,
    value: 50,
  });
  const vehicleId = useRecoilValue<number | false>(isOnlinePanelOpenAtom);
  const onlinePanelData = useRecoilValue<IVehicleOnlinePanels>(onlinePanelAtom);
  const setEvBatteryKwhWidgetGraph = useSetRecoilState<IWidgetGraph | null>(
    evBatteryKwWidgetGraphAtom
  );
  const setEvBatteryPercentWidgetGraph = useSetRecoilState<IWidgetGraph | null>(
    evBatteryPercWidgetGraphAtom
  );

  const getOnlinePanelKey = React.useCallback(() => {
    let key = '';
    switch (props.data.icon) {
      case EV_BATTERY_REMAINING:
        key = 'remaining';
        break;
      case EV_BATTERY_RANGE:
        key = 'batteryRange';
        break;
      case EV_BATTERY_PERCENT:
        key = 'percent';
        break;
      default:
        key = 'percent';
    }
    return key;
  }, [props.data.icon]);

  React.useMemo(() => {
    if (!props.isMockupMode) {
      const onlinePanel = onlinePanelData[Number(vehicleId)];
      const key = getOnlinePanelKey();

      if (
        onlinePanel &&
        onlinePanel.electric &&
        (onlinePanel.electric.percent !== null || onlinePanel.electric[key] !== null)
      ) {
        setWidgetData({
          percent: onlinePanel.electric.percent,
          value: onlinePanel.electric[key],
        });
      } else {
        setWidgetData(null);
      }
    }
  }, [getOnlinePanelKey, onlinePanelData, props.isMockupMode, vehicleId]);

  const getBatteryColor = () => {
    const value = widgetData?.percent;
    if (value === null || value === undefined) {
      return 'text-gray-light';
    }
    if (value < LOW_BATTERY_LEVEL) {
      return 'text-primary';
    }
    if (value < MIDDLE_BATTERY_LEVEL) {
      return '';
    }
    return 'text-green';
  };

  const getBatteryLevelValue = () => {
    const value = widgetData?.percent;
    if (value === null || value === undefined || value >= BATTERY_LEVEL_95) {
      return '100';
    }
    if (value >= BATTERY_LEVEL_80) {
      return '80';
    }
    if (value >= BATTERY_LEVEL_60) {
      return '60';
    }
    if (value >= BATTERY_LEVEL_40) {
      return '40';
    }
    if (value >= BATTERY_LEVEL_20) {
      return '20';
    }
    return '0';
  };

  const getValueType = () => {
    if (props.data.icon === EV_BATTERY_RANGE) {
      return 'km';
    }
    if (props.data.icon === EV_BATTERY_REMAINING) {
      return 'kWh';
    }
    if (props.data.icon === EV_BATTERY_PERCENT) {
      return '%';
    }
    return '';
  };

  const handleDetail = () => {
    if (typeof widgetData === 'object' && widgetData !== null) {
      if (props.data.icon === EV_BATTERY_REMAINING) {
        setEvBatteryKwhWidgetGraph({
          vehicleId: vehicleId || 0,
        });
      }
      if (props.data.icon === EV_BATTERY_PERCENT) {
        setEvBatteryPercentWidgetGraph({
          vehicleId: vehicleId || 0,
        });
      }
      if (props.data.icon === EV_BATTERY) {
        setEvBatteryPercentWidgetGraph({
          vehicleId: vehicleId || 0,
        });
      }
    }
  };

  return (
    <div
      data-cy="battery-widget"
      onClick={props.isMockupMode ? undefined : handleDetail}
      className={`b-widget is-clickable b-widget--${props.data.rowSize}x${props.data.columnSize} ${
        props.data.hasIcon && props.data.columnSize > 1 ? 'b-widget--alignLeft' : ''
      } widget-grid-name-${props.data.gridName} ${props.isInGrid ? '' : 'droppable-element'}`}
      draggable={!props.isInGrid}
      onDragStart={props.isInGrid ? undefined : props.onDragCallback}
      title={getWidgetTooltip(
        f({
          id: `widget.${
            props.data.icon === EV_BATTERY ? EV_BATTERY_PERCENT : props.data.icon
          }.tooltip`,
        }),
        f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' }),
        props.isInGrid,
        props.isMockupMode
      )}
    >
      {props.isInGrid && (
        <RemoveButton
          dataCy="electro-battery-widget-in-grid"
          onRemoveCallback={props.onRemoveCallback}
          gridName={props.data.gridName}
        />
      )}

      <i
        className={`e-widget-icon e-widget-icon--md cmd-icon cmd-icon-battery-level-${getBatteryLevelValue()} ${getBatteryColor()}`}
      />
      {props.data.icon !== EV_BATTERY && (
        <div className={getBatteryColor()}>
          {getWidgetValue(widgetData?.value, true, getValueType())}
        </div>
      )}
    </div>
  );
}
