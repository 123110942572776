import * as React from 'react';
import { useIntl } from 'react-intl';
import { CmdInput, CmdModal, CmdModalFooterButton } from '@commander-services/gui-components';
import { Controller, useForm } from 'react-hook-form';
import FormikValidatorService from '../../Services/FormikValidatorService';
import ChangePasswordService from '../../Services/ChangePasswordService';
import usePageWithForm from '../../hooks/usePageWithForm';
import HookFormService from '../../Services/HookFormService';

interface IFormProps {
  handleClose: () => void;
  data: any;
}

interface IChangePasswordFormProps {
  currentPassword: string;
  password: string;
  confirmPassword: string;
}

export default function Form(props: IFormProps): JSX.Element {
  usePageWithForm();

  const [isLoaderActive, setIsLoaderActive] = React.useState<boolean>(false);
  const { formatMessage: f } = useIntl();

  // fields data
  const currentPassword = props.data.filter((i) => i.indexId === 'current_password')[0];
  const password = props.data.filter((i) => i.indexId === 'password')[0];
  const confirmPassword = props.data.filter((i) => i.indexId === 'confirm_password')[0];

  const getRules = (rules) => {
    return rules.map((i) => {
      if (i.param === 'min') {
        i.param = 'minLength';
      } else if (i.param === 'max') {
        i.param = 'maxLength';
      }
      return i;
    });
  };

  const validateFields = (values) => {
    let formErrors = {};
    const validation = {
      currentPassword:
        currentPassword && currentPassword.rules
          ? currentPassword.rules.filter((i) => i.param !== 'assertTrue')
          : [],
      password: password && password.rules ? getRules(password.rules) : [],
      confirmPassword: confirmPassword && confirmPassword.rules ? confirmPassword.rules : [],
    };
    formErrors = FormikValidatorService.validateByValidationApiResponse(values, validation);
    return HookFormService.transformErrors(formErrors);
  };

  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    setError,
    formState: { errors },
  } = useForm<IChangePasswordFormProps>({
    mode: 'onChange',
    defaultValues: {
      currentPassword: '',
      password: '',
      confirmPassword: '',
    },
    resolver: async (data) => {
      const formErrors = validateFields(data);
      return {
        values: formErrors && Object.keys(formErrors).length ? {} : data, // if there's an error, return empty values
        errors: formErrors,
      };
    },
  });

  const onSubmit = async (values) => {
    setIsLoaderActive(true);
    const response = await ChangePasswordService.submitForm(
      values.currentPassword,
      values.password,
      values.confirmPassword
    );
    setIsLoaderActive(false);
    if (response) {
      props.handleClose();
    } else {
      trigger('currentPassword');
      setError('currentPassword', { message: f({ id: 'form.rules.incorrectPassword' }) });
    }
  };

  const modalFooterButtons: CmdModalFooterButton[] = [
    {
      id: 'closeChangePasswordModal',
      type: 'button',
      className: 'e-button',
      title: f({ id: 'form.close' }),
      closeCallback: props.handleClose,
    },
    {
      id: 'submitChangePasswordModal',
      type: 'submit',
      className: 'e-button e-button--gray',
      title: f({ id: 'form.save' }),
      submitCallback: handleSubmit(onSubmit),
    },
  ];

  return (
    <CmdModal
      id="change-password-formik-form"
      title={f({ id: 'changePassword.modal.title' })}
      footerButtons={modalFooterButtons}
      loader={isLoaderActive}
    >
      <div>
        <Controller
          name="currentPassword"
          control={control}
          rules={{ required: errors.currentPassword ? errors.currentPassword.message : undefined }}
          render={({ field }) => (
            <CmdInput
              {...field}
              id="currentPassword"
              name="currentPassword"
              label={currentPassword && currentPassword.title ? currentPassword.title : ''}
              type="password"
              onChangeCallback={(value) => {
                setValue('currentPassword', value);
                trigger('currentPassword');
              }}
              error={errors.currentPassword?.message}
              required
              touched
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          rules={{ required: errors.password ? errors.password.message : undefined }}
          render={({ field }) => (
            <CmdInput
              {...field}
              id="password"
              name="password"
              label={password && password.title ? password.title : ''}
              type="password"
              onChangeCallback={(value) => {
                setValue('password', value);
                trigger('password');
              }}
              error={errors.password?.message}
              required
              touched
            />
          )}
        />
        <Controller
          name="confirmPassword"
          control={control}
          rules={{ required: errors.confirmPassword ? errors.confirmPassword.message : undefined }}
          render={({ field }) => (
            <CmdInput
              {...field}
              id="confirmPassword"
              name="confirmPassword"
              label={confirmPassword && confirmPassword.title ? confirmPassword.title : ''}
              type="password"
              onChangeCallback={(value) => {
                setValue('confirmPassword', value);
                trigger('confirmPassword');
              }}
              error={errors.confirmPassword?.message}
              required
              touched
            />
          )}
        />
      </div>
    </CmdModal>
  );
}
