import * as React from 'react';
import { CmdCalendar } from '@commander-services/gui-components';
import { useIntl } from 'react-intl';
// import '../CmdCalendar/calendar.scss';

export interface IOption {
  item: string;
  value: string;
  label?: string;
  tooltip?: string;
  disabled?: boolean;
}

export interface IGroupSelectOptions {
  [key: string]: IOption[];
}

export interface IGroupSelectOptionAction {
  item: string;
  value: string;
  title: string;
  icon: string;
  actionCallback: (event) => void;
}

interface ICmdFieldProps {
  id: string;
  name: string;
  mode?: string;
  label: string;
  tooltip?: string;
  dateFormat?: string;
  allowedRange?: Date[] | string[];
  allowedMaxDays?: number;
  initialValue?: Date[];
  disabledItems?: string[];
  beforeToday?: boolean;
  afterToday?: boolean;
  note?: string;
  error?: string;
  touched?: boolean;
  onChangeCallback?: (value: Date) => void;
}

export const calendarLanguage = localStorage.getItem('language') || undefined;

export const calendarRangesTranslations = (f) => {
  return {
    TODAY: f({ id: 'datepicker.TODAY' }),
    YESTERDAY: f({ id: 'datepicker.YESTERDAY' }),
    LAST_WEEK: f({ id: 'datepicker.LAST_WEEK' }),
    THIS_MONTH: f({ id: 'datepicker.THIS_MONTH' }),
    PREVIOUS_MONTH: f({ id: 'datepicker.PREVIOUS_MONTH' }),
    THIS_YEAR: f({ id: 'datepicker.THIS_YEAR' }),
    PREVIOUS_YEAR: f({ id: 'datepicker.PREVIOUS_YEAR' }),
  };
};

export const calendarTranslations = (f) => {
  return {
    sunday: f({ id: 'date.sunday' }).slice(0, 2),
    monday: f({ id: 'date.monday' }).slice(0, 2),
    tuesday: f({ id: 'date.tuesday' }).slice(0, 2),
    wednesday: f({ id: 'date.wednesday' }).slice(0, 2),
    thursday: f({ id: 'date.thursday' }).slice(0, 2),
    friday: f({ id: 'date.friday' }).slice(0, 2),
    saturday: f({ id: 'date.saturday' }).slice(0, 2),
    ok: f({ id: 'table.apply' }),
    today: f({ id: 'datepicker.TODAY' }),
    yesterday: f({ id: 'datepicker.YESTERDAY' }),
    hours: localStorage.getItem('language') === 'en' ? 'Hr' : f({ id: 'date.hours' }).slice(0, 3),
    minutes: f({ id: 'date.minutes' }).slice(0, 3),
    seconds: f({ id: 'date.seconds' }).slice(0, 3),
  };
};

export default function Calendar(props: ICmdFieldProps) {
  const { formatMessage: f } = useIntl();

  const getCalendarProps = () => {
    return {
      language: calendarLanguage,
      rangesTranslations: calendarRangesTranslations(f),
      calendarTranslations: calendarTranslations(f),
    };
  };

  return <CmdCalendar {...props} {...getCalendarProps()} />;
}
