import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  isOnlinePanelOpenAtom,
  IVehicleOnlinePanels,
  IWidgetGraph,
  onlinePanelAtom,
} from '../OnlinePanelState';
import { ICustomWidgetProps } from './Widget';
import { throttleWidgetGraphAtom } from '../../Graphs/GraphState';
import { IVehicleData } from '../../Vehicles/types';
import RemoveButton from './RemoveButton';
import { getWidgetTooltip, getWidgetValue, isWidgetDisabled } from './WidgetService';
import { vehicleDataByIdSelector } from '../../../store/recoil/vehiclesData';

export const THROTTLE = 'throttle';

export default function ThrottleWidget(props: ICustomWidgetProps): JSX.Element {
  const { formatMessage: f } = useIntl();
  const [widgetData, setWidgetData] = React.useState<number | null>(67);
  const setThrottleWidgetGraph = useSetRecoilState<IWidgetGraph | null>(throttleWidgetGraphAtom);
  const vehicleId = useRecoilValue<number | false>(isOnlinePanelOpenAtom);
  const onlinePanelData = useRecoilValue<IVehicleOnlinePanels>(onlinePanelAtom);
  const vehicleData = useRecoilValue<IVehicleData | null>(vehicleDataByIdSelector(vehicleId));
  const shortName = f({ id: 'widget.throttle.valueName.short' });
  const longName = f({ id: 'widget.throttle.valueName.long' });
  const valueType = '%';
  const HIGH_PERCENTAGE = 66;

  React.useMemo(() => {
    if (!props.isMockupMode) {
      const onlinePanel = onlinePanelData[Number(vehicleId)];

      if (onlinePanel && onlinePanel.throttle !== null) {
        const roundedValue = Number(onlinePanel.throttle).toFixed(2);
        setWidgetData(Number(roundedValue));
      } else {
        setWidgetData(null);
      }
    }
  }, [onlinePanelData, props.isMockupMode, vehicleId]);

  const handleDetail = () => {
    if (typeof widgetData === 'number' && vehicleData) {
      // const rideId = vehicleData.lastRide?.id;
      setThrottleWidgetGraph({
        vehicleId: vehicleId || 0,
      });
    }
  };

  return (
    <div
      data-cy="throttle-widget"
      onClick={props.isMockupMode ? undefined : handleDetail}
      className={`b-widget is-clickable b-widget--${props.data.rowSize}x${props.data.columnSize} ${
        props.data.hasIcon && props.data.columnSize > 1 ? 'b-widget--alignLeft' : ''
      } widget-grid-name-${props.data.gridName} ${props.isInGrid ? '' : 'droppable-element'}`}
      draggable={!props.isInGrid}
      onDragStart={props.isInGrid ? undefined : props.onDragCallback}
      title={getWidgetTooltip(
        f({ id: 'widget.throttle.tooltip' }),
        f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' }),
        props.isInGrid,
        props.isMockupMode
      )}
      style={props.isMockupMode ? undefined : { cursor: 'pointer' }}
    >
      {props.isInGrid && (
        <RemoveButton
          dataCy="throttle-widget-in-grid"
          onRemoveCallback={props.onRemoveCallback}
          gridName={props.data.gridName}
        />
      )}
      {props.data.hasTitle && props.data.columnSize === 1 && !props.data.hasIcon && (
        <h4 data-cy="throttle-widget-title-size-1" className="e-widget-title">
          {shortName}
        </h4>
      )}
      {props.data.hasTitle && props.data.columnSize > 1 && !props.data.hasIcon && (
        <h4 data-cy="throttle-widget-title-size-more-1" className="e-widget-title">
          {longName}
        </h4>
      )}
      {props.data.hasTitle && props.data.columnSize === 2 && props.data.hasIcon && (
        <h4 data-cy="throttle-widget-title-size-2" className="e-widget-title">
          {longName}
        </h4>
      )}
      {props.data.hasIcon && props.data.columnSize > 1 && (
        <div data-cy="throttle-widget-icon-size-more-1" className="row no-gutters">
          <div className="col-auto">
            {props.data.columnSize === 2 && (
              <i
                className={`e-widget-icon cmd-icon cmd-icon-peddle ${
                  widgetData !== null && !props.isMockupMode && widgetData >= HIGH_PERCENTAGE
                    ? 'active'
                    : ''
                } ${isWidgetDisabled(widgetData)}`}
              />
            )}
            {props.data.columnSize === 3 && (
              <i
                className={`e-widget-icon e-widget-icon--md cmd-icon cmd-icon-peddle ${
                  widgetData !== null && !props.isMockupMode && widgetData >= HIGH_PERCENTAGE
                    ? 'active'
                    : ''
                } ${isWidgetDisabled(widgetData)}`}
              />
            )}
          </div>
          <div className="col">
            {props.data.hasTitle && props.data.columnSize === 3 && (
              <h4 className="e-widget-title">{longName}</h4>
            )}
            <div className={`e-widget-value e-widget-value--md ${isWidgetDisabled(widgetData)}`}>
              {getWidgetValue(widgetData, props.data.hasUnitType, valueType)}
            </div>
          </div>
        </div>
      )}
      {!props.data.hasIcon && props.data.columnSize > 1 && (
        <div
          data-cy="throttle-widget-value-size-more-1"
          className={`e-widget-value e-widget-value--md ${isWidgetDisabled(widgetData)}`}
        >
          {getWidgetValue(widgetData, props.data.hasUnitType, valueType)}
        </div>
      )}
      {props.data.hasIcon && props.data.columnSize === 1 && (
        <i
          data-cy="throttle-widget-icon-size-1"
          className={`e-widget-icon cmd-icon cmd-icon-peddle ${
            widgetData !== null && !props.isMockupMode && widgetData >= HIGH_PERCENTAGE
              ? 'active'
              : ''
          } ${isWidgetDisabled(widgetData)}`}
        />
      )}
      {props.data.columnSize === 1 && (
        <div
          data-cy="throttle-widget-value-size-1"
          className={`e-widget-value ${isWidgetDisabled(widgetData)}`}
        >
          {getWidgetValue(widgetData, props.data.hasUnitType, valueType)}
        </div>
      )}
    </div>
  );
}
